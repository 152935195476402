
var CanvasKitInit = (function() {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  if (typeof __filename !== 'undefined') _scriptDir = _scriptDir || __filename;
  return (
function(CanvasKitInit) {
  CanvasKitInit = CanvasKitInit || {};


var t;t||(t=typeof CanvasKitInit !== 'undefined' ? CanvasKitInit : {});var aa,ca;t.ready=new Promise(function(a,b){aa=a;ca=b});
(function(a){a.Ud=a.Ud||[];a.Ud.push(function(){a.MakeSWCanvasSurface=function(b){var c=b;if("CANVAS"!==c.tagName&&(c=document.getElementById(b),!c))throw"Canvas with id "+b+" was not found";if(b=a.MakeSurface(c.width,c.height))b.Hd=c;return b};a.MakeCanvasSurface||(a.MakeCanvasSurface=a.MakeSWCanvasSurface);a.MakeSurface=function(b,c){var e={width:b,height:c,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB},g=b*c*4,m=a._malloc(g);if(e=a.Surface._makeRasterDirect(e,
m,4*b))e.Hd=null,e.zf=b,e.wf=c,e.yf=g,e.$e=m,e.getCanvas().clear(a.TRANSPARENT);return e};a.MakeRasterDirectSurface=function(b,c,e){return a.Surface._makeRasterDirect(b,c.byteOffset,e)};a.Surface.prototype.flush=function(b){this._flush();if(this.Hd){var c=new Uint8ClampedArray(a.HEAPU8.buffer,this.$e,this.yf);c=new ImageData(c,this.zf,this.wf);b?this.Hd.getContext("2d").putImageData(c,0,0,b[0],b[1],b[2]-b[0],b[3]-b[1]):this.Hd.getContext("2d").putImageData(c,0,0)}};a.Surface.prototype.dispose=function(){this.$e&&
a._free(this.$e);this.delete()};a.currentContext=a.currentContext||function(){};a.setCurrentContext=a.setCurrentContext||function(){}})})(t);
(function(a){a.Ud=a.Ud||[];a.Ud.push(function(){function b(c,e,g){return c&&c.hasOwnProperty(e)?c[e]:g}a.GetWebGLContext=function(c,e){if(!c)throw"null canvas passed into makeWebGLContext";var g={alpha:b(e,"alpha",1),depth:b(e,"depth",1),stencil:b(e,"stencil",8),antialias:b(e,"antialias",0),premultipliedAlpha:b(e,"premultipliedAlpha",1),preserveDrawingBuffer:b(e,"preserveDrawingBuffer",0),preferLowPowerToHighPerformance:b(e,"preferLowPowerToHighPerformance",0),failIfMajorPerformanceCaveat:b(e,"failIfMajorPerformanceCaveat",
0),enableExtensionsByDefault:b(e,"enableExtensionsByDefault",1),explicitSwapControl:b(e,"explicitSwapControl",0),renderViaOffscreenBackBuffer:b(e,"renderViaOffscreenBackBuffer",0)};g.majorVersion=e&&e.majorVersion?e.majorVersion:"undefined"!==typeof WebGL2RenderingContext?2:1;if(g.explicitSwapControl)throw"explicitSwapControl is not supported";c=fa(c,g);if(!c)return 0;ia(c);return c};a.deleteContext=function(c){u===ja[c]&&(u=null);"object"===typeof JSEvents&&JSEvents.wg(ja[c].Ce.canvas);ja[c]&&ja[c].Ce.canvas&&
(ja[c].Ce.canvas.jf=void 0);ja[c]=null};a.MakeWebGLCanvasSurface=function(c,e,g){e=e||null;var m=c,p="undefined"!==typeof OffscreenCanvas&&m instanceof OffscreenCanvas;if(!("undefined"!==typeof HTMLCanvasElement&&m instanceof HTMLCanvasElement||p||(m=document.getElementById(c),m)))throw"Canvas with id "+c+" was not found";c=this.GetWebGLContext(m,g);if(!c||0>c)throw"failed to create webgl context: err "+c;g=this.MakeGrContext(c);e=this.MakeOnScreenGLSurface(g,m.width,m.height,e);if(!e)return e=m.cloneNode(!0),
m.parentNode.replaceChild(e,m),e.classList.add("ck-replaced"),a.MakeSWCanvasSurface(e);e.he=c;e.grContext=g;e.openGLversion=m.jf.version;return e};a.MakeCanvasSurface=a.MakeWebGLCanvasSurface})})(t);
(function(a){function b(f,d,h,l,q){for(var z=0;z<f.length;z++)d[z*h+(z*q+l+h)%h]=f[z];return d}function c(f){for(var d=f*f,h=Array(d);d--;)h[d]=0===d%(f+1)?1:0;return h}function e(f){return f?f.constructor===Float32Array&&4===f.length:!1}function g(f){return(r(255*f[3])<<24|r(255*f[0])<<16|r(255*f[1])<<8|r(255*f[2])<<0)>>>0}function m(f){if(f instanceof Float32Array){for(var d=Math.floor(f.length/4),h=new Uint32Array(d),l=0;l<d;l++)h[l]=g(f.slice(4*l,4*(l+1)));return h}if(f instanceof Uint32Array)return f;
if(f instanceof Array&&f[0]instanceof Float32Array)return f.map(g)}function p(f){if(void 0===f)return 1;var d=parseFloat(f);return f&&-1!==f.indexOf("%")?d/100:d}function r(f){return Math.round(Math.max(0,Math.min(f||0,255)))}function x(f,d){d&&!d._ck&&a._free(f)}function v(f,d,h){if(!f||!f.length)return da;if(f._ck)return f.byteOffset;var l=a[d].BYTES_PER_ELEMENT;h||(h=a._malloc(f.length*l));a[d].set(f,h/l);return h}function E(f){var d={Zd:da,count:f.length,Je:a.ColorType.RGBA_F32};if(f instanceof
Float32Array)d.Zd=v(f,"HEAPF32"),d.count=f.length/4;else if(f instanceof Uint32Array)d.Zd=v(f,"HEAPU32"),d.Je=a.ColorType.RGBA_8888;else if(f instanceof Array){if(f&&f.length){for(var h=a._malloc(16*f.length),l=0,q=h/4,z=0;z<f.length;z++)for(var C=0;4>C;C++)a.HEAPF32[q+l]=f[z][C],l++;f=h}else f=da;d.Zd=f}else throw"Invalid argument to copyFlexibleColorArray, Not a color array "+typeof f;return d}function I(f){if(!f)return da;if(f.length){if(6===f.length||9===f.length)return v(f,"HEAPF32",Ha),6===
f.length&&a.HEAPF32.set(Fd,6+Ha/4),Ha;if(16===f.length){var d=Ab.toTypedArray();d[0]=f[0];d[1]=f[1];d[2]=f[3];d[3]=f[4];d[4]=f[5];d[5]=f[7];d[6]=f[12];d[7]=f[13];d[8]=f[15];return Ha}throw"invalid matrix size";}d=Ab.toTypedArray();d[0]=f.m11;d[1]=f.m21;d[2]=f.m41;d[3]=f.m12;d[4]=f.m22;d[5]=f.m42;d[6]=f.m14;d[7]=f.m24;d[8]=f.m44;return Ha}function L(f){if(!f)return da;var d=cc.toTypedArray();if(f.length){if(16!==f.length&&6!==f.length&&9!==f.length)throw"invalid matrix size";if(16===f.length)return v(f,
"HEAPF32",Ia);d.fill(0);d[0]=f[0];d[1]=f[1];d[3]=f[2];d[4]=f[3];d[5]=f[4];d[7]=f[5];d[12]=f[6];d[13]=f[7];d[15]=f[8];6===f.length&&(d[12]=0,d[13]=0,d[15]=1);return Ia}d[0]=f.m11;d[1]=f.m21;d[2]=f.m31;d[3]=f.m41;d[4]=f.m12;d[5]=f.m22;d[6]=f.m32;d[7]=f.m42;d[8]=f.m13;d[9]=f.m23;d[10]=f.m33;d[11]=f.m43;d[12]=f.m14;d[13]=f.m24;d[14]=f.m34;d[15]=f.m44;return Ia}function y(f){for(var d=Array(16),h=0;16>h;h++)d[h]=a.HEAPF32[f/4+h];return d}function K(f,d){return v(f,"HEAPF32",d||gb)}function T(f,d,h,l){var q=
dc.toTypedArray();q[0]=f;q[1]=d;q[2]=h;q[3]=l;return gb}function Z(f){for(var d=new Float32Array(4),h=0;4>h;h++)d[h]=a.HEAPF32[f/4+h];return d}function X(f,d){return v(f,"HEAPF32",d||ha)}function la(f,d){return v(f,"HEAPF32",d||ec)}function hb(){for(var f=0,d=0;d<arguments.length-1;d+=2)f+=arguments[d]*arguments[d+1];return f}function ib(f,d,h){for(var l=Array(f.length),q=0;q<h;q++)for(var z=0;z<h;z++){for(var C=0,H=0;H<h;H++)C+=f[h*q+H]*d[h*H+z];l[q*h+z]=C}return l}function Mc(f,d){for(var h=ib(d[0],
d[1],f),l=2;l<d.length;)h=ib(h,d[l],f),l++;return h}a.Color=function(f,d,h,l){void 0===l&&(l=1);return a.Color4f(r(f)/255,r(d)/255,r(h)/255,l)};a.ColorAsInt=function(f,d,h,l){void 0===l&&(l=255);return(r(l)<<24|r(f)<<16|r(d)<<8|r(h)<<0&268435455)>>>0};a.Color4f=function(f,d,h,l){void 0===l&&(l=1);return Float32Array.of(f,d,h,l)};Object.defineProperty(a,"TRANSPARENT",{get:function(){return a.Color4f(0,0,0,0)}});Object.defineProperty(a,"BLACK",{get:function(){return a.Color4f(0,0,0,1)}});Object.defineProperty(a,
"WHITE",{get:function(){return a.Color4f(1,1,1,1)}});Object.defineProperty(a,"RED",{get:function(){return a.Color4f(1,0,0,1)}});Object.defineProperty(a,"GREEN",{get:function(){return a.Color4f(0,1,0,1)}});Object.defineProperty(a,"BLUE",{get:function(){return a.Color4f(0,0,1,1)}});Object.defineProperty(a,"YELLOW",{get:function(){return a.Color4f(1,1,0,1)}});Object.defineProperty(a,"CYAN",{get:function(){return a.Color4f(0,1,1,1)}});Object.defineProperty(a,"MAGENTA",{get:function(){return a.Color4f(1,
0,1,1)}});a.getColorComponents=function(f){return[Math.floor(255*f[0]),Math.floor(255*f[1]),Math.floor(255*f[2]),f[3]]};a.parseColorString=function(f,d){f=f.toLowerCase();if(f.startsWith("#")){d=255;switch(f.length){case 9:d=parseInt(f.slice(7,9),16);case 7:var h=parseInt(f.slice(1,3),16);var l=parseInt(f.slice(3,5),16);var q=parseInt(f.slice(5,7),16);break;case 5:d=17*parseInt(f.slice(4,5),16);case 4:h=17*parseInt(f.slice(1,2),16),l=17*parseInt(f.slice(2,3),16),q=17*parseInt(f.slice(3,4),16)}return a.Color(h,
l,q,d/255)}return f.startsWith("rgba")?(f=f.slice(5,-1),f=f.split(","),a.Color(+f[0],+f[1],+f[2],p(f[3]))):f.startsWith("rgb")?(f=f.slice(4,-1),f=f.split(","),a.Color(+f[0],+f[1],+f[2],p(f[3]))):f.startsWith("gray(")||f.startsWith("hsl")||!d||(f=d[f],void 0===f)?a.BLACK:f};a.multiplyByAlpha=function(f,d){f=f.slice();f[3]=Math.max(0,Math.min(f[3]*d,1));return f};a.Malloc=function(f,d){var h=a._malloc(d*f.BYTES_PER_ELEMENT);return{_ck:!0,length:d,byteOffset:h,je:null,subarray:function(l,q){l=this.toTypedArray().subarray(l,
q);l._ck=!0;return l},toTypedArray:function(){if(this.je&&this.je.length)return this.je;this.je=new f(a.HEAPU8.buffer,h,d);this.je._ck=!0;return this.je}}};a.Free=function(f){a._free(f.byteOffset);f.byteOffset=da;f.toTypedArray=null;f.je=null};var Ha=da,Ab,Ia=da,cc,gb=da,dc,xa,ha=da,Nc,Ra=da,Oc,fc=da,Pc,hc=da,Qc,ic=da,Rc,ec=da,Sc,Tc=da,Fd=Float32Array.of(0,0,1),jc={};a.ke=function(){this.ge=[];this.Od=null;Object.defineProperty(this,"length",{enumerable:!0,get:function(){return this.ge.length/4}})};
a.ke.prototype.push=function(f,d,h,l){this.Od||this.ge.push(f,d,h,l)};a.ke.prototype.set=function(f,d,h,l,q){0>f||f>=this.ge.length/4||(f*=4,this.Od?(f=this.Od/4+f,a.HEAPF32[f]=d,a.HEAPF32[f+1]=h,a.HEAPF32[f+2]=l,a.HEAPF32[f+3]=q):(this.ge[f]=d,this.ge[f+1]=h,this.ge[f+2]=l,this.ge[f+3]=q))};a.ke.prototype.build=function(){return this.Od?this.Od:this.Od=v(this.ge,"HEAPF32")};a.ke.prototype.delete=function(){this.Od&&(a._free(this.Od),this.Od=null)};a.De=function(){this.Ie=[];this.Od=null;Object.defineProperty(this,
"length",{enumerable:!0,get:function(){return this.Ie.length}})};a.De.prototype.push=function(f){this.Od||this.Ie.push(f)};a.De.prototype.set=function(f,d){0>f||f>=this.Ie.length||(f*=4,this.Od?a.HEAPU32[this.Od/4+f]=d:this.Ie[f]=d)};a.De.prototype.build=function(){return this.Od?this.Od:this.Od=v(this.Ie,"HEAPU32")};a.De.prototype.delete=function(){this.Od&&(a._free(this.Od),this.Od=null)};a.RectBuilder=a.ke;a.RSXFormBuilder=a.ke;a.ColorBuilder=a.De;var da=0,Gd=!(new Function("try {return this===window;}catch(e){ return false;}"))();
a.onRuntimeInitialized=function(){function f(d,h,l,q,z,C){C||(C=4*q.width,q.colorType===a.ColorType.RGBA_F16?C*=2:q.colorType===a.ColorType.RGBA_F32&&(C*=4));var H=C*q.height;var M=z?z.byteOffset:a._malloc(H);if(!d._readPixels(q,M,C,h,l))return z||a._free(M),null;if(z)return z.toTypedArray();switch(q.colorType){case a.ColorType.RGBA_8888:case a.ColorType.RGBA_F16:d=(new Uint8Array(a.HEAPU8.buffer,M,H)).slice();break;case a.ColorType.RGBA_F32:d=(new Float32Array(a.HEAPU8.buffer,M,H)).slice();break;
default:return null}a._free(M);return d}dc=a.Malloc(Float32Array,4);gb=dc.byteOffset;cc=a.Malloc(Float32Array,16);Ia=cc.byteOffset;Ab=a.Malloc(Float32Array,9);Ha=Ab.byteOffset;Rc=a.Malloc(Float32Array,12);ec=Rc.byteOffset;Sc=a.Malloc(Float32Array,12);Tc=Sc.byteOffset;xa=a.Malloc(Float32Array,4);ha=xa.byteOffset;Nc=a.Malloc(Float32Array,4);Ra=Nc.byteOffset;Oc=a.Malloc(Float32Array,3);fc=Oc.byteOffset;Pc=a.Malloc(Float32Array,3);hc=Pc.byteOffset;Qc=a.Malloc(Int32Array,4);ic=Qc.byteOffset;a.ColorSpace.SRGB=
a.ColorSpace._MakeSRGB();a.ColorSpace.DISPLAY_P3=a.ColorSpace._MakeDisplayP3();a.ColorSpace.ADOBE_RGB=a.ColorSpace._MakeAdobeRGB();a.Path.MakeFromCmds=function(d){for(var h=0,l=0;l<d.length;l++)h+=d[l].length;if(jc[h])var q=jc[h];else q=new Float32Array(h),jc[h]=q;var z=0;for(l=0;l<d.length;l++)for(var C=0;C<d[l].length;C++)q[z]=d[l][C],z++;d=[v(q,"HEAPF32"),h];h=a.Path._MakeFromCmds(d[0],d[1]);a._free(d[0]);return h};a.Path.MakeFromVerbsPointsWeights=function(d,h,l){var q=v(d,"HEAPU8"),z=v(h,"HEAPF32"),
C=v(l,"HEAPF32"),H=a.Path._MakeFromVerbsPointsWeights(q,d.length,z,h.length,C,l&&l.length||0);x(q,d);x(z,h);x(C,l);return H};a.Path.prototype.addArc=function(d,h,l){d=X(d);this._addArc(d,h,l);return this};a.Path.prototype.addOval=function(d,h,l){void 0===l&&(l=1);d=X(d);this._addOval(d,!!h,l);return this};a.Path.prototype.addPath=function(){var d=Array.prototype.slice.call(arguments),h=d[0],l=!1;"boolean"===typeof d[d.length-1]&&(l=d.pop());if(1===d.length)this._addPath(h,1,0,0,0,1,0,0,0,1,l);else if(2===
d.length)d=d[1],this._addPath(h,d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1,l);else if(7===d.length||10===d.length)this._addPath(h,d[1],d[2],d[3],d[4],d[5],d[6],d[7]||0,d[8]||0,d[9]||1,l);else return null;return this};a.Path.prototype.addPoly=function(d,h){var l=v(d,"HEAPF32");this._addPoly(l,d.length/2,h);x(l,d);return this};a.Path.prototype.addRect=function(d,h){d=X(d);this._addRect(d,!!h);return this};a.Path.prototype.addRRect=function(d,h){d=la(d);this._addRRect(d,!!h);return this};
a.Path.prototype.addVerbsPointsWeights=function(d,h,l){var q=v(d,"HEAPU8"),z=v(h,"HEAPF32"),C=v(l,"HEAPF32");this._addVerbsPointsWeights(q,d.length,z,h.length,C,l&&l.length||0);x(q,d);x(z,h);x(C,l)};a.Path.prototype.arc=function(d,h,l,q,z,C){d=a.LTRBRect(d-l,h-l,d+l,h+l);z=(z-q)/Math.PI*180-360*!!C;C=new a.Path;C.addArc(d,q/Math.PI*180,z);this.addPath(C,!0);C.delete();return this};a.Path.prototype.arcToOval=function(d,h,l,q){d=X(d);this._arcToOval(d,h,l,q);return this};a.Path.prototype.arcToRotated=
function(d,h,l,q,z,C,H){this._arcToRotated(d,h,l,!!q,!!z,C,H);return this};a.Path.prototype.arcToTangent=function(d,h,l,q,z){this._arcToTangent(d,h,l,q,z);return this};a.Path.prototype.close=function(){this._close();return this};a.Path.prototype.conicTo=function(d,h,l,q,z){this._conicTo(d,h,l,q,z);return this};a.Path.prototype.computeTightBounds=function(d){this._computeTightBounds(ha);var h=xa.toTypedArray();return d?(d.set(h),d):h.slice()};a.Path.prototype.cubicTo=function(d,h,l,q,z,C){this._cubicTo(d,
h,l,q,z,C);return this};a.Path.prototype.dash=function(d,h,l){return this._dash(d,h,l)?this:null};a.Path.prototype.getBounds=function(d){this._getBounds(ha);var h=xa.toTypedArray();return d?(d.set(h),d):h.slice()};a.Path.prototype.lineTo=function(d,h){this._lineTo(d,h);return this};a.Path.prototype.moveTo=function(d,h){this._moveTo(d,h);return this};a.Path.prototype.offset=function(d,h){this._transform(1,0,d,0,1,h,0,0,1);return this};a.Path.prototype.quadTo=function(d,h,l,q){this._quadTo(d,h,l,q);
return this};a.Path.prototype.rArcTo=function(d,h,l,q,z,C,H){this._rArcTo(d,h,l,q,z,C,H);return this};a.Path.prototype.rConicTo=function(d,h,l,q,z){this._rConicTo(d,h,l,q,z);return this};a.Path.prototype.rCubicTo=function(d,h,l,q,z,C){this._rCubicTo(d,h,l,q,z,C);return this};a.Path.prototype.rLineTo=function(d,h){this._rLineTo(d,h);return this};a.Path.prototype.rMoveTo=function(d,h){this._rMoveTo(d,h);return this};a.Path.prototype.rQuadTo=function(d,h,l,q){this._rQuadTo(d,h,l,q);return this};a.Path.prototype.stroke=
function(d){d=d||{};d.width=d.width||1;d.miter_limit=d.miter_limit||4;d.cap=d.cap||a.StrokeCap.Butt;d.join=d.join||a.StrokeJoin.Miter;d.precision=d.precision||1;return this._stroke(d)?this:null};a.Path.prototype.transform=function(){if(1===arguments.length){var d=arguments[0];this._transform(d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1)}else if(6===arguments.length||9===arguments.length)d=arguments,this._transform(d[0],d[1],d[2],d[3],d[4],d[5],d[6]||0,d[7]||0,d[8]||1);else throw"transform expected to take 1 or 9 arguments. Got "+
arguments.length;return this};a.Path.prototype.trim=function(d,h,l){return this._trim(d,h,!!l)?this:null};a.Image.prototype.makeShaderCubic=function(d,h,l,q,z){z=I(z);return this._makeShaderCubic(d,h,l,q,z)};a.Image.prototype.makeShaderOptions=function(d,h,l,q,z){z=I(z);return this._makeShaderOptions(d,h,l,q,z)};a.Image.prototype.readPixels=function(d,h,l,q,z){return f(this,d,h,l,q,z)};a.Canvas.prototype.clear=function(d){d=K(d);this._clear(d)};a.Canvas.prototype.clipRRect=function(d,h,l){d=la(d);
this._clipRRect(d,h,l)};a.Canvas.prototype.clipRect=function(d,h,l){d=X(d);this._clipRect(d,h,l)};a.Canvas.prototype.concat=function(d){d=L(d);this._concat(d)};a.Canvas.prototype.drawArc=function(d,h,l,q,z){d=X(d);this._drawArc(d,h,l,q,z)};a.Canvas.prototype.drawAtlas=function(d,h,l,q,z,C,H){if(d&&q&&h&&l&&h.length===l.length){z||(z=a.BlendMode.SrcOver);var M=h.build?h.build():v(h,"HEAPF32");if(l.build){var O=l.build();var R=l.length}else O=v(l,"HEAPF32"),R=l.length/4;var P=da;C&&(P=C.build?C.build():
v(m(C),"HEAPU32"));if(H&&"B"in H&&"C"in H)this._drawAtlasCubic(d,O,M,P,R,z,H.B,H.C,q);else{let ea=a.FilterMode.Linear,na=a.MipmapMode.None;H&&(ea=H.filter,"mipmap"in H&&(na=H.mipmap));this._drawAtlasOptions(d,O,M,P,R,z,ea,na,q)}M&&!h.build&&x(M,h);O&&!l.build&&x(O,l);P&&!C.build&&x(P,C)}};a.Canvas.prototype.drawColor=function(d,h){d=K(d);void 0!==h?this._drawColor(d,h):this._drawColor(d)};a.Canvas.prototype.drawColorComponents=function(d,h,l,q,z){d=T(d,h,l,q);void 0!==z?this._drawColor(d,z):this._drawColor(d)};
a.Canvas.prototype.drawDRRect=function(d,h,l){d=la(d,ec);h=la(h,Tc);this._drawDRRect(d,h,l)};a.Canvas.prototype.drawImageNine=function(d,h,l,q,z){h=v(h,"HEAP32",ic);l=X(l);this._drawImageNine(d,h,l,q,z||null)};a.Canvas.prototype.drawImageRect=function(d,h,l,q,z){X(h,ha);X(l,Ra);this._drawImageRect(d,ha,Ra,q,!!z)};a.Canvas.prototype.drawImageRectCubic=function(d,h,l,q,z,C){X(h,ha);X(l,Ra);this._drawImageRectCubic(d,ha,Ra,q,z,C||null)};a.Canvas.prototype.drawImageRectOptions=function(d,h,l,q,z,C){X(h,
ha);X(l,Ra);this._drawImageRectOptions(d,ha,Ra,q,z,C||null)};a.Canvas.prototype.drawOval=function(d,h){d=X(d);this._drawOval(d,h)};a.Canvas.prototype.drawPoints=function(d,h,l){var q=v(h,"HEAPF32");this._drawPoints(d,q,h.length/2,l);x(q,h)};a.Canvas.prototype.drawRRect=function(d,h){d=la(d);this._drawRRect(d,h)};a.Canvas.prototype.drawRect=function(d,h){d=X(d);this._drawRect(d,h)};a.Canvas.prototype.drawShadow=function(d,h,l,q,z,C,H){var M=v(z,"HEAPF32"),O=v(C,"HEAPF32");h=v(h,"HEAPF32",fc);l=v(l,
"HEAPF32",hc);this._drawShadow(d,h,l,q,M,O,H);x(M,z);x(O,C)};a.getShadowLocalBounds=function(d,h,l,q,z,C,H){d=I(d);l=v(l,"HEAPF32",fc);q=v(q,"HEAPF32",hc);if(!this._getShadowLocalBounds(d,h,l,q,z,C,ha))return null;h=xa.toTypedArray();return H?(H.set(h),H):h.slice()};a.Canvas.prototype.getLocalToDevice=function(){this._getLocalToDevice(Ia);return y(Ia)};a.Canvas.prototype.findMarkedCTM=function(d){return this._findMarkedCTM(d,Ia)?y(Ia):null};a.Canvas.prototype.getTotalMatrix=function(){this._getTotalMatrix(Ha);
for(var d=Array(9),h=0;9>h;h++)d[h]=a.HEAPF32[Ha/4+h];return d};a.Canvas.prototype.readPixels=function(d,h,l,q,z){return f(this,d,h,l,q,z)};a.Canvas.prototype.saveLayer=function(d,h,l,q){h=X(h);return this._saveLayer(d||null,h,l||null,q||0)};a.Canvas.prototype.writePixels=function(d,h,l,q,z,C,H,M){if(d.byteLength%(h*l))throw"pixels length must be a multiple of the srcWidth * srcHeight";var O=d.byteLength/(h*l);C=C||a.AlphaType.Unpremul;H=H||a.ColorType.RGBA_8888;M=M||a.ColorSpace.SRGB;var R=O*h;O=
v(d,"HEAPU8");h=this._writePixels({width:h,height:l,colorType:H,alphaType:C,colorSpace:M},O,R,q,z);x(O,d);return h};a.ColorFilter.MakeBlend=function(d,h){d=K(d);return a.ColorFilter._MakeBlend(d,h)};a.ColorFilter.MakeMatrix=function(d){if(!d||20!==d.length)throw"invalid color matrix";var h=v(d,"HEAPF32"),l=a.ColorFilter._makeMatrix(h);x(h,d);return l};a.ContourMeasure.prototype.getPosTan=function(d,h){this._getPosTan(d,ha);d=xa.toTypedArray();return h?(h.set(d),h):d.slice()};a.ImageFilter.MakeMatrixTransform=
function(d,h,l){d=I(d);return a.ImageFilter._MakeMatrixTransform(d,h,l)};a.Paint.prototype.getColor=function(){this._getColor(gb);return Z(gb)};a.Paint.prototype.setColor=function(d,h){h=h||null;d=K(d);this._setColor(d,h)};a.Paint.prototype.setColorComponents=function(d,h,l,q,z){z=z||null;d=T(d,h,l,q);this._setColor(d,z)};a.Path.prototype.getPoint=function(d,h){this._getPoint(d,ha);d=xa.toTypedArray();return h?(h[0]=d[0],h[1]=d[1],h):d.slice(0,2)};a.PictureRecorder.prototype.beginRecording=function(d){d=
X(d);return this._beginRecording(d)};a.Surface.prototype.makeImageSnapshot=function(d){d=v(d,"HEAP32",ic);return this._makeImageSnapshot(d)};a.Surface.prototype.requestAnimationFrame=function(d,h){this.Ee||(this.Ee=this.getCanvas());requestAnimationFrame(function(){void 0!==this.he&&a.setCurrentContext(this.he);d(this.Ee);this.flush(h)}.bind(this))};a.Surface.prototype.drawOnce=function(d,h){this.Ee||(this.Ee=this.getCanvas());requestAnimationFrame(function(){void 0!==this.he&&a.setCurrentContext(this.he);
d(this.Ee);this.flush(h);this.dispose()}.bind(this))};a.PathEffect.MakeDash=function(d,h){h||(h=0);if(!d.length||1===d.length%2)throw"Intervals array must have even length";var l=v(d,"HEAPF32");h=a.PathEffect._MakeDash(l,d.length,h);x(l,d);return h};a.Shader.MakeColor=function(d,h){h=h||null;d=K(d);return a.Shader._MakeColor(d,h)};a.Shader.Blend=a.Shader.MakeBlend;a.Shader.Color=a.Shader.MakeColor;a.Shader.Lerp=a.Shader.MakeLerp;a.Shader.MakeLinearGradient=function(d,h,l,q,z,C,H,M){M=M||null;var O=
E(l),R=v(q,"HEAPF32");H=H||0;C=I(C);var P=xa.toTypedArray();P.set(d);P.set(h,2);d=a.Shader._MakeLinearGradient(ha,O.Zd,O.Je,R,O.count,z,H,C,M);x(O.Zd,l);q&&x(R,q);return d};a.Shader.MakeRadialGradient=function(d,h,l,q,z,C,H,M){M=M||null;var O=E(l),R=v(q,"HEAPF32");H=H||0;C=I(C);d=a.Shader._MakeRadialGradient(d[0],d[1],h,O.Zd,O.Je,R,O.count,z,H,C,M);x(O.Zd,l);q&&x(R,q);return d};a.Shader.MakeSweepGradient=function(d,h,l,q,z,C,H,M,O,R){R=R||null;var P=E(l),ea=v(q,"HEAPF32");H=H||0;M=M||0;O=O||360;C=
I(C);d=a.Shader._MakeSweepGradient(d,h,P.Zd,P.Je,ea,P.count,z,M,O,H,C,R);x(P.Zd,l);q&&x(ea,q);return d};a.Shader.MakeTwoPointConicalGradient=function(d,h,l,q,z,C,H,M,O,R){R=R||null;var P=E(z),ea=v(C,"HEAPF32");O=O||0;M=I(M);var na=xa.toTypedArray();na.set(d);na.set(l,2);d=a.Shader._MakeTwoPointConicalGradient(ha,h,q,P.Zd,P.Je,ea,P.count,H,O,M,R);x(P.Zd,z);C&&x(ea,C);return d};a.Vertices.prototype.bounds=function(d){this._bounds(ha);var h=xa.toTypedArray();return d?(d.set(h),d):h.slice()};a.Ud&&a.Ud.forEach(function(d){d()})};
a.computeTonalColors=function(f){var d=v(f.ambient,"HEAPF32"),h=v(f.spot,"HEAPF32");this._computeTonalColors(d,h);var l={ambient:Z(d),spot:Z(h)};x(d,f.ambient);x(h,f.spot);return l};a.LTRBRect=function(f,d,h,l){return Float32Array.of(f,d,h,l)};a.XYWHRect=function(f,d,h,l){return Float32Array.of(f,d,f+h,d+l)};a.LTRBiRect=function(f,d,h,l){return Int32Array.of(f,d,h,l)};a.XYWHiRect=function(f,d,h,l){return Int32Array.of(f,d,f+h,d+l)};a.RRectXY=function(f,d,h){return Float32Array.of(f[0],f[1],f[2],f[3],
d,h,d,h,d,h,d,h)};a.MakeAnimatedImageFromEncoded=function(f){f=new Uint8Array(f);var d=a._malloc(f.byteLength);a.HEAPU8.set(f,d);return(f=a._decodeAnimatedImage(d,f.byteLength))?f:null};a.MakeImageFromEncoded=function(f){f=new Uint8Array(f);var d=a._malloc(f.byteLength);a.HEAPU8.set(f,d);return(f=a._decodeImage(d,f.byteLength))?f:null};var jb=null;a.MakeImageFromCanvasImageSource=function(f){var d=f.width,h=f.height;jb||(jb=document.createElement("canvas"));jb.width=d;jb.height=h;var l=jb.getContext("2d");
l.drawImage(f,0,0);f=l.getImageData(0,0,d,h);return a.MakeImage({width:d,height:h,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},f.data,4*d)};a.MakeImage=function(f,d,h){var l=a._malloc(d.length);a.HEAPU8.set(d,l);return a._MakeImage(f,l,d.length,h)};a.MakeVertices=function(f,d,h,l,q,z){var C=q&&q.length||0,H=0;h&&h.length&&(H|=1);l&&l.length&&(H|=2);void 0===z||z||(H|=4);f=new a._VerticesBuilder(f,d.length/2,C,H);v(d,"HEAPF32",f.positions());f.texCoords()&&
v(h,"HEAPF32",f.texCoords());if(f.colors()){if(l.build)throw"Color builder not accepted by MakeVertices, use array of ints";v(m(l),"HEAPU32",f.colors())}f.indices()&&v(q,"HEAPU16",f.indices());return f.detach()};a.Matrix={};a.Matrix.identity=function(){return c(3)};a.Matrix.invert=function(f){var d=f[0]*f[4]*f[8]+f[1]*f[5]*f[6]+f[2]*f[3]*f[7]-f[2]*f[4]*f[6]-f[1]*f[3]*f[8]-f[0]*f[5]*f[7];return d?[(f[4]*f[8]-f[5]*f[7])/d,(f[2]*f[7]-f[1]*f[8])/d,(f[1]*f[5]-f[2]*f[4])/d,(f[5]*f[6]-f[3]*f[8])/d,(f[0]*
f[8]-f[2]*f[6])/d,(f[2]*f[3]-f[0]*f[5])/d,(f[3]*f[7]-f[4]*f[6])/d,(f[1]*f[6]-f[0]*f[7])/d,(f[0]*f[4]-f[1]*f[3])/d]:null};a.Matrix.mapPoints=function(f,d){for(var h=0;h<d.length;h+=2){var l=d[h],q=d[h+1],z=f[6]*l+f[7]*q+f[8],C=f[3]*l+f[4]*q+f[5];d[h]=(f[0]*l+f[1]*q+f[2])/z;d[h+1]=C/z}return d};a.Matrix.multiply=function(){return Mc(3,arguments)};a.Matrix.rotated=function(f,d,h){d=d||0;h=h||0;var l=Math.sin(f);f=Math.cos(f);return[f,-l,hb(l,h,1-f,d),l,f,hb(-l,d,1-f,h),0,0,1]};a.Matrix.scaled=function(f,
d,h,l){h=h||0;l=l||0;var q=b([f,d],c(3),3,0,1);return b([h-f*h,l-d*l],q,3,2,0)};a.Matrix.skewed=function(f,d,h,l){h=h||0;l=l||0;var q=b([f,d],c(3),3,1,-1);return b([-f*h,-d*l],q,3,2,0)};a.Matrix.translated=function(f,d){return b(arguments,c(3),3,2,0)};a.Vector={};a.Vector.dot=function(f,d){return f.map(function(h,l){return h*d[l]}).reduce(function(h,l){return h+l})};a.Vector.lengthSquared=function(f){return a.Vector.dot(f,f)};a.Vector.length=function(f){return Math.sqrt(a.Vector.lengthSquared(f))};
a.Vector.mulScalar=function(f,d){return f.map(function(h){return h*d})};a.Vector.add=function(f,d){return f.map(function(h,l){return h+d[l]})};a.Vector.sub=function(f,d){return f.map(function(h,l){return h-d[l]})};a.Vector.dist=function(f,d){return a.Vector.length(a.Vector.sub(f,d))};a.Vector.normalize=function(f){return a.Vector.mulScalar(f,1/a.Vector.length(f))};a.Vector.cross=function(f,d){return[f[1]*d[2]-f[2]*d[1],f[2]*d[0]-f[0]*d[2],f[0]*d[1]-f[1]*d[0]]};a.M44={};a.M44.identity=function(){return c(4)};
a.M44.translated=function(f){return b(f,c(4),4,3,0)};a.M44.scaled=function(f){return b(f,c(4),4,0,1)};a.M44.rotated=function(f,d){return a.M44.rotatedUnitSinCos(a.Vector.normalize(f),Math.sin(d),Math.cos(d))};a.M44.rotatedUnitSinCos=function(f,d,h){var l=f[0],q=f[1];f=f[2];var z=1-h;return[z*l*l+h,z*l*q-d*f,z*l*f+d*q,0,z*l*q+d*f,z*q*q+h,z*q*f-d*l,0,z*l*f-d*q,z*q*f+d*l,z*f*f+h,0,0,0,0,1]};a.M44.lookat=function(f,d,h){d=a.Vector.normalize(a.Vector.sub(d,f));h=a.Vector.normalize(h);h=a.Vector.normalize(a.Vector.cross(d,
h));var l=a.M44.identity();b(h,l,4,0,0);b(a.Vector.cross(h,d),l,4,1,0);b(a.Vector.mulScalar(d,-1),l,4,2,0);b(f,l,4,3,0);f=a.M44.invert(l);return null===f?a.M44.identity():f};a.M44.perspective=function(f,d,h){var l=1/(d-f);h/=2;h=Math.cos(h)/Math.sin(h);return[h,0,0,0,0,h,0,0,0,0,(d+f)*l,2*d*f*l,0,0,-1,1]};a.M44.rc=function(f,d,h){return f[4*d+h]};a.M44.multiply=function(){return Mc(4,arguments)};a.M44.invert=function(f){var d=f[0],h=f[4],l=f[8],q=f[12],z=f[1],C=f[5],H=f[9],M=f[13],O=f[2],R=f[6],P=
f[10],ea=f[14],na=f[3],Da=f[7],Ea=f[11];f=f[15];var Ja=d*C-h*z,Ka=d*H-l*z,pa=d*M-q*z,G=h*H-l*C,k=h*M-q*C,n=l*M-q*H,w=O*Da-R*na,A=O*Ea-P*na,B=O*f-ea*na,D=R*Ea-P*Da,J=R*f-ea*Da,U=P*f-ea*Ea,ma=Ja*U-Ka*J+pa*D+G*B-k*A+n*w,ba=1/ma;if(0===ma||Infinity===ba)return null;Ja*=ba;Ka*=ba;pa*=ba;G*=ba;k*=ba;n*=ba;w*=ba;A*=ba;B*=ba;D*=ba;J*=ba;U*=ba;d=[C*U-H*J+M*D,H*B-z*U-M*A,z*J-C*B+M*w,C*A-z*D-H*w,l*J-h*U-q*D,d*U-l*B+q*A,h*B-d*J-q*w,d*D-h*A+l*w,Da*n-Ea*k+f*G,Ea*pa-na*n-f*Ka,na*k-Da*pa+f*Ja,Da*Ka-na*G-Ea*Ja,P*
k-R*n-ea*G,O*n-P*pa+ea*Ka,R*pa-O*k-ea*Ja,O*G-R*Ka+P*Ja];return d.every(function(ya){return!isNaN(ya)&&Infinity!==ya&&-Infinity!==ya})?d:null};a.M44.transpose=function(f){return[f[0],f[4],f[8],f[12],f[1],f[5],f[9],f[13],f[2],f[6],f[10],f[14],f[3],f[7],f[11],f[15]]};a.M44.mustInvert=function(f){f=a.M44.invert(f);if(null===f)throw"Matrix not invertible";return f};a.M44.setupCamera=function(f,d,h){var l=a.M44.lookat(h.eye,h.coa,h.up);h=a.M44.perspective(h.near,h.far,h.angle);d=[(f[2]-f[0])/2,(f[3]-f[1])/
2,d];f=a.M44.multiply(a.M44.translated([(f[0]+f[2])/2,(f[1]+f[3])/2,0]),a.M44.scaled(d));return a.M44.multiply(f,h,l,a.M44.mustInvert(f))};a.ColorMatrix={};a.ColorMatrix.identity=function(){var f=new Float32Array(20);f[0]=1;f[6]=1;f[12]=1;f[18]=1;return f};a.ColorMatrix.scaled=function(f,d,h,l){var q=new Float32Array(20);q[0]=f;q[6]=d;q[12]=h;q[18]=l;return q};var Hd=[[6,7,11,12],[0,10,2,12],[0,1,5,6]];a.ColorMatrix.rotated=function(f,d,h){var l=a.ColorMatrix.identity();f=Hd[f];l[f[0]]=h;l[f[1]]=
d;l[f[2]]=-d;l[f[3]]=h;return l};a.ColorMatrix.postTranslate=function(f,d,h,l,q){f[4]+=d;f[9]+=h;f[14]+=l;f[19]+=q;return f};a.ColorMatrix.concat=function(f,d){for(var h=new Float32Array(20),l=0,q=0;20>q;q+=5){for(var z=0;4>z;z++)h[l++]=f[q]*d[z]+f[q+1]*d[z+5]+f[q+2]*d[z+10]+f[q+3]*d[z+15];h[l++]=f[q]*d[4]+f[q+1]*d[9]+f[q+2]*d[14]+f[q+3]*d[19]+f[q+4]}return h};a.Ud=a.Ud||[];a.Ud.push(function(){a.Path.prototype.op=function(f,d){return this._op(f,d)?this:null};a.Path.prototype.simplify=function(){return this._simplify()?
this:null}});a.Ud=a.Ud||[];a.Ud.push(function(){a.Canvas.prototype.drawText=function(f,d,h,l,q){var z=ka(f),C=a._malloc(z+1);oa(f,F,C,z+1);this._drawSimpleText(C,z,d,h,q,l);a._free(C)};a.Font.prototype.getGlyphBounds=function(f,d,h){var l=v(f,"HEAPU16"),q=a._malloc(16*f.length);this._getGlyphWidthBounds(l,f.length,da,q,d||null);d=new Float32Array(a.HEAPU8.buffer,q,4*f.length);x(l,f);if(h)return h.set(d),a._free(q),h;f=Float32Array.from(d);a._free(q);return f};a.Font.prototype.getGlyphIDs=function(f,
d,h){d||(d=f.length);var l=ka(f)+1,q=a._malloc(l);oa(f,F,q,l);f=a._malloc(2*d);d=this._getGlyphIDs(q,l-1,d,f);a._free(q);if(0>d)return a._free(f),null;q=new Uint16Array(a.HEAPU8.buffer,f,d);if(h)return h.set(q),a._free(f),h;h=Uint32Array.from(q);a._free(f);return h};a.Font.prototype.getGlyphWidths=function(f,d,h){var l=v(f,"HEAPU16"),q=a._malloc(4*f.length);this._getGlyphWidthBounds(l,f.length,q,da,d||null);d=new Float32Array(a.HEAPU8.buffer,q,f.length);x(l,f);if(h)return h.set(d),a._free(q),h;f=
Float32Array.from(d);a._free(q);return f};a.FontMgr.FromData=function(){if(!arguments.length)return null;var f=arguments;1===f.length&&Array.isArray(f[0])&&(f=arguments[0]);if(!f.length)return null;for(var d=[],h=[],l=0;l<f.length;l++){var q=new Uint8Array(f[l]),z=v(q,"HEAPU8");d.push(z);h.push(q.byteLength)}d=v(d,"HEAPU32");h=v(h,"HEAPU32");f=a.FontMgr._fromData(d,h,f.length);a._free(d);a._free(h);return f};a.FontMgr.prototype.MakeTypefaceFromData=function(f){f=new Uint8Array(f);var d=v(f,"HEAPU8");
return(f=this._makeTypefaceFromData(d,f.byteLength))?f:null};a.TextBlob.MakeOnPath=function(f,d,h,l){if(f&&f.length&&d&&d.countPoints()){if(1===d.countPoints())return this.MakeFromText(f,h);l||(l=0);var q=h.getGlyphIDs(f),z=h.getGlyphWidths(q);q=new a.RSXFormBuilder;d=new a.ContourMeasureIter(d,!1,1);for(var C=d.next(),H=new Float32Array(4),M=0;M<f.length&&C;M++){var O=z[M];l+=O/2;if(l>C.length()){C.delete();C=d.next();if(!C){f=f.substring(0,M);break}l=O/2}C.getPosTan(l,H);var R=H[2],P=H[3];q.push(R,
P,H[0]-O/2*R,H[1]-O/2*P);l+=O/2}f=this.MakeFromRSXform(f,q,h);q.delete();C&&C.delete();d.delete();return f}};a.TextBlob.MakeFromRSXform=function(f,d,h){var l=ka(f)+1,q=a._malloc(l);oa(f,F,q,l);f=d.build?d.build():v(d,"HEAPF32");h=a.TextBlob._MakeFromRSXform(q,l-1,f,h);a._free(q);return h?h:null};a.TextBlob.MakeFromRSXformGlyphs=function(f,d,h){var l=v(f,"HEAPU16");d=d.build?d.build():v(d,"HEAPF32");h=a.TextBlob._MakeFromRSXformGlyphs(l,2*f.length,d,h);x(l,f);return h?h:null};a.TextBlob.MakeFromGlyphs=
function(f,d){var h=v(f,"HEAPU16");d=a.TextBlob._MakeFromGlyphs(h,2*f.length,d);x(h,f);return d?d:null};a.TextBlob.MakeFromText=function(f,d){var h=ka(f)+1,l=a._malloc(h);oa(f,F,l,h);f=a.TextBlob._MakeFromText(l,h-1,d);a._free(l);return f?f:null};a.MallocGlyphIDs=function(f){return a.Malloc(Uint16Array,f)}});(function(){function f(G){for(var k=0;k<G.length;k++)if(void 0!==G[k]&&!Number.isFinite(G[k]))return!1;return!0}function d(G){var k=a.getColorComponents(G);G=k[0];var n=k[1],w=k[2];k=k[3];if(1===
k)return G=G.toString(16).toLowerCase(),n=n.toString(16).toLowerCase(),w=w.toString(16).toLowerCase(),G=1===G.length?"0"+G:G,n=1===n.length?"0"+n:n,w=1===w.length?"0"+w:w,"#"+G+n+w;k=0===k||1===k?k:k.toFixed(8);return"rgba("+G+", "+n+", "+w+", "+k+")"}function h(G){return a.parseColorString(G,Ja)}function l(G){G=Ka.exec(G);if(!G)return null;var k=parseFloat(G[4]),n=16;switch(G[5]){case "em":case "rem":n=16*k;break;case "pt":n=4*k/3;break;case "px":n=k;break;case "pc":n=16*k;break;case "in":n=96*k;
break;case "cm":n=96*k/2.54;break;case "mm":n=96/25.4*k;break;case "q":n=96/25.4/4*k;break;case "%":n=16/75*k}return{style:G[1],variant:G[2],weight:G[3],sizePx:n,family:G[6].trim()}}function q(G){this.Hd=G;this.Jd=new a.Paint;this.Jd.setAntiAlias(!0);this.Jd.setStrokeMiter(10);this.Jd.setStrokeCap(a.StrokeCap.Butt);this.Jd.setStrokeJoin(a.StrokeJoin.Miter);this.Oe="10px monospace";this.ne=new a.Font(null,10);this.ne.setSubpixel(!0);this.Wd=this.be=a.BLACK;this.qe=0;this.Ge=a.TRANSPARENT;this.se=this.re=
0;this.He=this.de=1;this.Fe=0;this.pe=[];this.Id=a.BlendMode.SrcOver;this.Jd.setStrokeWidth(this.He);this.Jd.setBlendMode(this.Id);this.Ld=new a.Path;this.Nd=a.Matrix.identity();this.kf=[];this.we=[];this.fe=function(){this.Ld.delete();this.Jd.delete();this.ne.delete();this.we.forEach(function(k){k.fe()})};Object.defineProperty(this,"currentTransform",{enumerable:!0,get:function(){return{a:this.Nd[0],c:this.Nd[1],e:this.Nd[2],b:this.Nd[3],d:this.Nd[4],f:this.Nd[5]}},set:function(k){k.a&&this.setTransform(k.a,
k.b,k.c,k.d,k.e,k.f)}});Object.defineProperty(this,"fillStyle",{enumerable:!0,get:function(){return e(this.Wd)?d(this.Wd):this.Wd},set:function(k){"string"===typeof k?this.Wd=h(k):k.oe&&(this.Wd=k)}});Object.defineProperty(this,"font",{enumerable:!0,get:function(){return this.Oe},set:function(k){var n=l(k),w=n.family;n.typeface=pa[w]?pa[w][(n.style||"normal")+"|"+(n.variant||"normal")+"|"+(n.weight||"normal")]||pa[w]["*"]:null;n&&(this.ne.setSize(n.sizePx),this.ne.setTypeface(n.typeface),this.Oe=
k)}});Object.defineProperty(this,"globalAlpha",{enumerable:!0,get:function(){return this.de},set:function(k){!isFinite(k)||0>k||1<k||(this.de=k)}});Object.defineProperty(this,"globalCompositeOperation",{enumerable:!0,get:function(){switch(this.Id){case a.BlendMode.SrcOver:return"source-over";case a.BlendMode.DstOver:return"destination-over";case a.BlendMode.Src:return"copy";case a.BlendMode.Dst:return"destination";case a.BlendMode.Clear:return"clear";case a.BlendMode.SrcIn:return"source-in";case a.BlendMode.DstIn:return"destination-in";
case a.BlendMode.SrcOut:return"source-out";case a.BlendMode.DstOut:return"destination-out";case a.BlendMode.SrcATop:return"source-atop";case a.BlendMode.DstATop:return"destination-atop";case a.BlendMode.Xor:return"xor";case a.BlendMode.Plus:return"lighter";case a.BlendMode.Multiply:return"multiply";case a.BlendMode.Screen:return"screen";case a.BlendMode.Overlay:return"overlay";case a.BlendMode.Darken:return"darken";case a.BlendMode.Lighten:return"lighten";case a.BlendMode.ColorDodge:return"color-dodge";
case a.BlendMode.ColorBurn:return"color-burn";case a.BlendMode.HardLight:return"hard-light";case a.BlendMode.SoftLight:return"soft-light";case a.BlendMode.Difference:return"difference";case a.BlendMode.Exclusion:return"exclusion";case a.BlendMode.Hue:return"hue";case a.BlendMode.Saturation:return"saturation";case a.BlendMode.Color:return"color";case a.BlendMode.Luminosity:return"luminosity"}},set:function(k){switch(k){case "source-over":this.Id=a.BlendMode.SrcOver;break;case "destination-over":this.Id=
a.BlendMode.DstOver;break;case "copy":this.Id=a.BlendMode.Src;break;case "destination":this.Id=a.BlendMode.Dst;break;case "clear":this.Id=a.BlendMode.Clear;break;case "source-in":this.Id=a.BlendMode.SrcIn;break;case "destination-in":this.Id=a.BlendMode.DstIn;break;case "source-out":this.Id=a.BlendMode.SrcOut;break;case "destination-out":this.Id=a.BlendMode.DstOut;break;case "source-atop":this.Id=a.BlendMode.SrcATop;break;case "destination-atop":this.Id=a.BlendMode.DstATop;break;case "xor":this.Id=
a.BlendMode.Xor;break;case "lighter":this.Id=a.BlendMode.Plus;break;case "plus-lighter":this.Id=a.BlendMode.Plus;break;case "plus-darker":throw"plus-darker is not supported";case "multiply":this.Id=a.BlendMode.Multiply;break;case "screen":this.Id=a.BlendMode.Screen;break;case "overlay":this.Id=a.BlendMode.Overlay;break;case "darken":this.Id=a.BlendMode.Darken;break;case "lighten":this.Id=a.BlendMode.Lighten;break;case "color-dodge":this.Id=a.BlendMode.ColorDodge;break;case "color-burn":this.Id=a.BlendMode.ColorBurn;
break;case "hard-light":this.Id=a.BlendMode.HardLight;break;case "soft-light":this.Id=a.BlendMode.SoftLight;break;case "difference":this.Id=a.BlendMode.Difference;break;case "exclusion":this.Id=a.BlendMode.Exclusion;break;case "hue":this.Id=a.BlendMode.Hue;break;case "saturation":this.Id=a.BlendMode.Saturation;break;case "color":this.Id=a.BlendMode.Color;break;case "luminosity":this.Id=a.BlendMode.Luminosity;break;default:return}this.Jd.setBlendMode(this.Id)}});Object.defineProperty(this,"imageSmoothingEnabled",
{enumerable:!0,get:function(){return!0},set:function(){}});Object.defineProperty(this,"imageSmoothingQuality",{enumerable:!0,get:function(){return"high"},set:function(){}});Object.defineProperty(this,"lineCap",{enumerable:!0,get:function(){switch(this.Jd.getStrokeCap()){case a.StrokeCap.Butt:return"butt";case a.StrokeCap.Round:return"round";case a.StrokeCap.Square:return"square"}},set:function(k){switch(k){case "butt":this.Jd.setStrokeCap(a.StrokeCap.Butt);break;case "round":this.Jd.setStrokeCap(a.StrokeCap.Round);
break;case "square":this.Jd.setStrokeCap(a.StrokeCap.Square)}}});Object.defineProperty(this,"lineDashOffset",{enumerable:!0,get:function(){return this.Fe},set:function(k){isFinite(k)&&(this.Fe=k)}});Object.defineProperty(this,"lineJoin",{enumerable:!0,get:function(){switch(this.Jd.getStrokeJoin()){case a.StrokeJoin.Miter:return"miter";case a.StrokeJoin.Round:return"round";case a.StrokeJoin.Bevel:return"bevel"}},set:function(k){switch(k){case "miter":this.Jd.setStrokeJoin(a.StrokeJoin.Miter);break;
case "round":this.Jd.setStrokeJoin(a.StrokeJoin.Round);break;case "bevel":this.Jd.setStrokeJoin(a.StrokeJoin.Bevel)}}});Object.defineProperty(this,"lineWidth",{enumerable:!0,get:function(){return this.Jd.getStrokeWidth()},set:function(k){0>=k||!k||(this.He=k,this.Jd.setStrokeWidth(k))}});Object.defineProperty(this,"miterLimit",{enumerable:!0,get:function(){return this.Jd.getStrokeMiter()},set:function(k){0>=k||!k||this.Jd.setStrokeMiter(k)}});Object.defineProperty(this,"shadowBlur",{enumerable:!0,
get:function(){return this.qe},set:function(k){0>k||!isFinite(k)||(this.qe=k)}});Object.defineProperty(this,"shadowColor",{enumerable:!0,get:function(){return d(this.Ge)},set:function(k){this.Ge=h(k)}});Object.defineProperty(this,"shadowOffsetX",{enumerable:!0,get:function(){return this.re},set:function(k){isFinite(k)&&(this.re=k)}});Object.defineProperty(this,"shadowOffsetY",{enumerable:!0,get:function(){return this.se},set:function(k){isFinite(k)&&(this.se=k)}});Object.defineProperty(this,"strokeStyle",
{enumerable:!0,get:function(){return d(this.be)},set:function(k){"string"===typeof k?this.be=h(k):k.oe&&(this.be=k)}});this.arc=function(k,n,w,A,B,D){P(this.Ld,k,n,w,w,0,A,B,D)};this.arcTo=function(k,n,w,A,B){M(this.Ld,k,n,w,A,B)};this.beginPath=function(){this.Ld.delete();this.Ld=new a.Path};this.bezierCurveTo=function(k,n,w,A,B,D){var J=this.Ld;f([k,n,w,A,B,D])&&(J.isEmpty()&&J.moveTo(k,n),J.cubicTo(k,n,w,A,B,D))};this.clearRect=function(k,n,w,A){this.Jd.setStyle(a.PaintStyle.Fill);this.Jd.setBlendMode(a.BlendMode.Clear);
this.Hd.drawRect(a.XYWHRect(k,n,w,A),this.Jd);this.Jd.setBlendMode(this.Id)};this.clip=function(k,n){"string"===typeof k?(n=k,k=this.Ld):k&&k.Ze&&(k=k.Pd);k||(k=this.Ld);k=k.copy();n&&"evenodd"===n.toLowerCase()?k.setFillType(a.FillType.EvenOdd):k.setFillType(a.FillType.Winding);this.Hd.clipPath(k,a.ClipOp.Intersect,!0);k.delete()};this.closePath=function(){O(this.Ld)};this.createImageData=function(){if(1===arguments.length){var k=arguments[0];return new C(new Uint8ClampedArray(4*k.width*k.height),
k.width,k.height)}if(2===arguments.length){k=arguments[0];var n=arguments[1];return new C(new Uint8ClampedArray(4*k*n),k,n)}throw"createImageData expects 1 or 2 arguments, got "+arguments.length;};this.createLinearGradient=function(k,n,w,A){if(f(arguments)){var B=new H(k,n,w,A);this.we.push(B);return B}};this.createPattern=function(k,n){k=new Da(k,n);this.we.push(k);return k};this.createRadialGradient=function(k,n,w,A,B,D){if(f(arguments)){var J=new Ea(k,n,w,A,B,D);this.we.push(J);return J}};this.drawImage=
function(k){var n=this.Ne();if(3===arguments.length||5===arguments.length)var w=a.XYWHRect(arguments[1],arguments[2],arguments[3]||k.width(),arguments[4]||k.height()),A=a.XYWHRect(0,0,k.width(),k.height());else if(9===arguments.length)w=a.XYWHRect(arguments[5],arguments[6],arguments[7],arguments[8]),A=a.XYWHRect(arguments[1],arguments[2],arguments[3],arguments[4]);else throw"invalid number of args for drawImage, need 3, 5, or 9; got "+arguments.length;this.Hd.drawImageRect(k,A,w,n,!1);n.dispose()};
this.ellipse=function(k,n,w,A,B,D,J,U){P(this.Ld,k,n,w,A,B,D,J,U)};this.Ne=function(){var k=this.Jd.copy();k.setStyle(a.PaintStyle.Fill);if(e(this.Wd)){var n=a.multiplyByAlpha(this.Wd,this.de);k.setColor(n)}else n=this.Wd.oe(this.Nd),k.setColor(a.Color(0,0,0,this.de)),k.setShader(n);k.dispose=function(){this.delete()};return k};this.fill=function(k,n){"string"===typeof k?(n=k,k=this.Ld):k&&k.Ze&&(k=k.Pd);if("evenodd"===n)this.Ld.setFillType(a.FillType.EvenOdd);else{if("nonzero"!==n&&n)throw"invalid fill rule";
this.Ld.setFillType(a.FillType.Winding)}k||(k=this.Ld);n=this.Ne();var w=this.te(n);w&&(this.Hd.save(),this.le(),this.Hd.drawPath(k,w),this.Hd.restore(),w.dispose());this.Hd.drawPath(k,n);n.dispose()};this.fillRect=function(k,n,w,A){var B=this.Ne(),D=this.te(B);D&&(this.Hd.save(),this.le(),this.Hd.drawRect(a.XYWHRect(k,n,w,A),D),this.Hd.restore(),D.dispose());this.Hd.drawRect(a.XYWHRect(k,n,w,A),B);B.dispose()};this.fillText=function(k,n,w){var A=this.Ne();k=a.TextBlob.MakeFromText(k,this.ne);var B=
this.te(A);B&&(this.Hd.save(),this.le(),this.Hd.drawTextBlob(k,n,w,B),this.Hd.restore(),B.dispose());this.Hd.drawTextBlob(k,n,w,A);k.delete();A.dispose()};this.getImageData=function(k,n,w,A){return(k=this.Hd.readPixels(k,n,{width:w,height:A,colorType:a.ColorType.RGBA_8888,alphaType:a.AlphaType.Unpremul,colorSpace:a.ColorSpace.SRGB}))?new C(new Uint8ClampedArray(k.buffer),w,A):null};this.getLineDash=function(){return this.pe.slice()};this.lf=function(k){var n=a.Matrix.invert(this.Nd);a.Matrix.mapPoints(n,
k);return k};this.isPointInPath=function(k,n,w){var A=arguments;if(3===A.length)var B=this.Ld;else if(4===A.length)B=A[0],k=A[1],n=A[2],w=A[3];else throw"invalid arg count, need 3 or 4, got "+A.length;if(!isFinite(k)||!isFinite(n))return!1;w=w||"nonzero";if("nonzero"!==w&&"evenodd"!==w)return!1;A=this.lf([k,n]);k=A[0];n=A[1];B.setFillType("nonzero"===w?a.FillType.Winding:a.FillType.EvenOdd);return B.contains(k,n)};this.isPointInStroke=function(k,n){var w=arguments;if(2===w.length)var A=this.Ld;else if(3===
w.length)A=w[0],k=w[1],n=w[2];else throw"invalid arg count, need 2 or 3, got "+w.length;if(!isFinite(k)||!isFinite(n))return!1;w=this.lf([k,n]);k=w[0];n=w[1];A=A.copy();A.setFillType(a.FillType.Winding);A.stroke({width:this.lineWidth,miter_limit:this.miterLimit,cap:this.Jd.getStrokeCap(),join:this.Jd.getStrokeJoin(),precision:.3});w=A.contains(k,n);A.delete();return w};this.lineTo=function(k,n){ea(this.Ld,k,n)};this.measureText=function(){throw Error("Clients wishing to properly measure text should use the Paragraph API");
};this.moveTo=function(k,n){var w=this.Ld;f([k,n])&&w.moveTo(k,n)};this.putImageData=function(k,n,w,A,B,D,J){if(f([n,w,A,B,D,J]))if(void 0===A)this.Hd.writePixels(k.data,k.width,k.height,n,w);else if(A=A||0,B=B||0,D=D||k.width,J=J||k.height,0>D&&(A+=D,D=Math.abs(D)),0>J&&(B+=J,J=Math.abs(J)),0>A&&(D+=A,A=0),0>B&&(J+=B,B=0),!(0>=D||0>=J)){k=a.MakeImage({width:k.width,height:k.height,alphaType:a.AlphaType.Unpremul,colorType:a.ColorType.RGBA_8888,colorSpace:a.ColorSpace.SRGB},k.data,4*k.width);var U=
a.XYWHRect(A,B,D,J);n=a.XYWHRect(n+A,w+B,D,J);w=a.Matrix.invert(this.Nd);this.Hd.save();this.Hd.concat(w);this.Hd.drawImageRect(k,U,n,null,!1);this.Hd.restore();k.delete()}};this.quadraticCurveTo=function(k,n,w,A){var B=this.Ld;f([k,n,w,A])&&(B.isEmpty()&&B.moveTo(k,n),B.quadTo(k,n,w,A))};this.rect=function(k,n,w,A){var B=this.Ld;k=a.XYWHRect(k,n,w,A);f(k)&&B.addRect(k)};this.resetTransform=function(){this.Ld.transform(this.Nd);var k=a.Matrix.invert(this.Nd);this.Hd.concat(k);this.Nd=this.Hd.getTotalMatrix()};
this.restore=function(){var k=this.kf.pop();if(k){var n=a.Matrix.multiply(this.Nd,a.Matrix.invert(k.Cf));this.Ld.transform(n);this.Jd.delete();this.Jd=k.Zf;this.pe=k.Vf;this.He=k.lg;this.be=k.kg;this.Wd=k.fs;this.re=k.ig;this.se=k.jg;this.qe=k.cg;this.Ge=k.hg;this.de=k.Jf;this.Id=k.Kf;this.Fe=k.Wf;this.Oe=k.If;this.Hd.restore();this.Nd=this.Hd.getTotalMatrix()}};this.rotate=function(k){if(isFinite(k)){var n=a.Matrix.rotated(-k);this.Ld.transform(n);this.Hd.rotate(k/Math.PI*180,0,0);this.Nd=this.Hd.getTotalMatrix()}};
this.save=function(){if(this.Wd.me){var k=this.Wd.me();this.we.push(k)}else k=this.Wd;if(this.be.me){var n=this.be.me();this.we.push(n)}else n=this.be;this.kf.push({Cf:this.Nd.slice(),Vf:this.pe.slice(),lg:this.He,kg:n,fs:k,ig:this.re,jg:this.se,cg:this.qe,hg:this.Ge,Jf:this.de,Wf:this.Fe,Kf:this.Id,Zf:this.Jd.copy(),If:this.Oe});this.Hd.save()};this.scale=function(k,n){if(f(arguments)){var w=a.Matrix.scaled(1/k,1/n);this.Ld.transform(w);this.Hd.scale(k,n);this.Nd=this.Hd.getTotalMatrix()}};this.setLineDash=
function(k){for(var n=0;n<k.length;n++)if(!isFinite(k[n])||0>k[n])return;1===k.length%2&&Array.prototype.push.apply(k,k);this.pe=k};this.setTransform=function(k,n,w,A,B,D){f(arguments)&&(this.resetTransform(),this.transform(k,n,w,A,B,D))};this.le=function(){var k=a.Matrix.invert(this.Nd);this.Hd.concat(k);this.Hd.concat(a.Matrix.translated(this.re,this.se));this.Hd.concat(this.Nd)};this.te=function(k){var n=a.multiplyByAlpha(this.Ge,this.de);if(!a.getColorComponents(n)[3]||!(this.qe||this.se||this.re))return null;
k=k.copy();k.setColor(n);var w=a.MaskFilter.MakeBlur(a.BlurStyle.Normal,this.qe/2,!1);k.setMaskFilter(w);k.dispose=function(){w.delete();this.delete()};return k};this.af=function(){var k=this.Jd.copy();k.setStyle(a.PaintStyle.Stroke);if(e(this.be)){var n=a.multiplyByAlpha(this.be,this.de);k.setColor(n)}else n=this.be.oe(this.Nd),k.setColor(a.Color(0,0,0,this.de)),k.setShader(n);k.setStrokeWidth(this.He);if(this.pe.length){var w=a.PathEffect.MakeDash(this.pe,this.Fe);k.setPathEffect(w)}k.dispose=function(){w&&
w.delete();this.delete()};return k};this.stroke=function(k){k=k?k.Pd:this.Ld;var n=this.af(),w=this.te(n);w&&(this.Hd.save(),this.le(),this.Hd.drawPath(k,w),this.Hd.restore(),w.dispose());this.Hd.drawPath(k,n);n.dispose()};this.strokeRect=function(k,n,w,A){var B=this.af(),D=this.te(B);D&&(this.Hd.save(),this.le(),this.Hd.drawRect(a.XYWHRect(k,n,w,A),D),this.Hd.restore(),D.dispose());this.Hd.drawRect(a.XYWHRect(k,n,w,A),B);B.dispose()};this.strokeText=function(k,n,w){var A=this.af();k=a.TextBlob.MakeFromText(k,
this.ne);var B=this.te(A);B&&(this.Hd.save(),this.le(),this.Hd.drawTextBlob(k,n,w,B),this.Hd.restore(),B.dispose());this.Hd.drawTextBlob(k,n,w,A);k.delete();A.dispose()};this.translate=function(k,n){if(f(arguments)){var w=a.Matrix.translated(-k,-n);this.Ld.transform(w);this.Hd.translate(k,n);this.Nd=this.Hd.getTotalMatrix()}};this.transform=function(k,n,w,A,B,D){k=[k,w,B,n,A,D,0,0,1];n=a.Matrix.invert(k);this.Ld.transform(n);this.Hd.concat(k);this.Nd=this.Hd.getTotalMatrix()};this.addHitRegion=function(){};
this.clearHitRegions=function(){};this.drawFocusIfNeeded=function(){};this.removeHitRegion=function(){};this.scrollPathIntoView=function(){};Object.defineProperty(this,"canvas",{value:null,writable:!1})}function z(G){this.bf=G;this.he=new q(G.getCanvas());this.Pe=[];this.vf=a.FontMgr.RefDefault();this.decodeImage=function(k){k=a.MakeImageFromEncoded(k);if(!k)throw"Invalid input";this.Pe.push(k);return k};this.loadFont=function(k,n){k=this.vf.MakeTypefaceFromData(k);if(!k)return null;this.Pe.push(k);
var w=(n.style||"normal")+"|"+(n.variant||"normal")+"|"+(n.weight||"normal");n=n.family;pa[n]||(pa[n]={"*":k});pa[n][w]=k};this.makePath2D=function(k){k=new na(k);this.Pe.push(k.Pd);return k};this.getContext=function(k){return"2d"===k?this.he:null};this.toDataURL=function(k,n){this.bf.flush();var w=this.bf.makeImageSnapshot();if(w){k=k||"image/png";var A=a.ImageFormat.PNG;"image/jpeg"===k&&(A=a.ImageFormat.JPEG);if(n=w.encodeToBytes(A,n||.92)){w.delete();k="data:"+k+";base64,";if(Gd)n=Buffer.from(n).toString("base64");
else{w=0;A=n.length;for(var B="",D;w<A;)D=n.slice(w,Math.min(w+32768,A)),B+=String.fromCharCode.apply(null,D),w+=32768;n=btoa(B)}return k+n}}};this.dispose=function(){this.he.fe();this.Pe.forEach(function(k){k.delete()});this.bf.dispose()}}function C(G,k,n){if(!k||0===n)throw"invalid dimensions, width and height must be non-zero";if(G.length%4)throw"arr must be a multiple of 4";n=n||G.length/(4*k);Object.defineProperty(this,"data",{value:G,writable:!1});Object.defineProperty(this,"height",{value:n,
writable:!1});Object.defineProperty(this,"width",{value:k,writable:!1})}function H(G,k,n,w){this.Rd=null;this.Yd=[];this.Td=[];this.addColorStop=function(A,B){if(0>A||1<A||!isFinite(A))throw"offset must be between 0 and 1 inclusively";B=h(B);var D=this.Td.indexOf(A);if(-1!==D)this.Yd[D]=B;else{for(D=0;D<this.Td.length&&!(this.Td[D]>A);D++);this.Td.splice(D,0,A);this.Yd.splice(D,0,B)}};this.me=function(){var A=new H(G,k,n,w);A.Yd=this.Yd.slice();A.Td=this.Td.slice();return A};this.fe=function(){this.Rd&&
(this.Rd.delete(),this.Rd=null)};this.oe=function(A){var B=[G,k,n,w];a.Matrix.mapPoints(A,B);A=B[0];var D=B[1],J=B[2];B=B[3];this.fe();return this.Rd=a.Shader.MakeLinearGradient([A,D],[J,B],this.Yd,this.Td,a.TileMode.Clamp)}}function M(G,k,n,w,A,B){if(f([k,n,w,A,B])){if(0>B)throw"radii cannot be negative";G.isEmpty()&&G.moveTo(k,n);G.arcToTangent(k,n,w,A,B)}}function O(G){if(!G.isEmpty()){var k=G.getBounds();(k[3]-k[1]||k[2]-k[0])&&G.close()}}function R(G,k,n,w,A,B,D){D=(D-B)/Math.PI*180;B=B/Math.PI*
180;k=a.LTRBRect(k-w,n-A,k+w,n+A);1E-5>Math.abs(Math.abs(D)-360)?(n=D/2,G.arcToOval(k,B,n,!1),G.arcToOval(k,B+n,n,!1)):G.arcToOval(k,B,D,!1)}function P(G,k,n,w,A,B,D,J,U){if(f([k,n,w,A,B,D,J])){if(0>w||0>A)throw"radii cannot be negative";var ma=2*Math.PI,ba=D%ma;0>ba&&(ba+=ma);var ya=ba-D;D=ba;J+=ya;!U&&J-D>=ma?J=D+ma:U&&D-J>=ma?J=D-ma:!U&&D>J?J=D+(ma-(D-J)%ma):U&&D<J&&(J=D-(ma-(J-D)%ma));B?(U=a.Matrix.rotated(B,k,n),B=a.Matrix.rotated(-B,k,n),G.transform(B),R(G,k,n,w,A,D,J),G.transform(U)):R(G,k,
n,w,A,D,J)}}function ea(G,k,n){f([k,n])&&(G.isEmpty()&&G.moveTo(k,n),G.lineTo(k,n))}function na(G){this.Pd=null;this.Pd="string"===typeof G?a.Path.MakeFromSVGString(G):G&&G.Ze?G.Pd.copy():new a.Path;this.Ze=function(){return this.Pd};this.addPath=function(k,n){n||(n={a:1,c:0,e:0,b:0,d:1,f:0});this.Pd.addPath(k.Pd,[n.a,n.c,n.e,n.b,n.d,n.f])};this.arc=function(k,n,w,A,B,D){P(this.Pd,k,n,w,w,0,A,B,D)};this.arcTo=function(k,n,w,A,B){M(this.Pd,k,n,w,A,B)};this.bezierCurveTo=function(k,n,w,A,B,D){var J=
this.Pd;f([k,n,w,A,B,D])&&(J.isEmpty()&&J.moveTo(k,n),J.cubicTo(k,n,w,A,B,D))};this.closePath=function(){O(this.Pd)};this.ellipse=function(k,n,w,A,B,D,J,U){P(this.Pd,k,n,w,A,B,D,J,U)};this.lineTo=function(k,n){ea(this.Pd,k,n)};this.moveTo=function(k,n){var w=this.Pd;f([k,n])&&w.moveTo(k,n)};this.quadraticCurveTo=function(k,n,w,A){var B=this.Pd;f([k,n,w,A])&&(B.isEmpty()&&B.moveTo(k,n),B.quadTo(k,n,w,A))};this.rect=function(k,n,w,A){var B=this.Pd;k=a.XYWHRect(k,n,w,A);f(k)&&B.addRect(k)}}function Da(G,
k){this.Rd=null;this.xf=G;this._transform=a.Matrix.identity();""===k&&(k="repeat");switch(k){case "repeat-x":this.ue=a.TileMode.Repeat;this.ve=a.TileMode.Decal;break;case "repeat-y":this.ue=a.TileMode.Decal;this.ve=a.TileMode.Repeat;break;case "repeat":this.ve=this.ue=a.TileMode.Repeat;break;case "no-repeat":this.ve=this.ue=a.TileMode.Decal;break;default:throw"invalid repetition mode "+k;}this.setTransform=function(n){n=[n.a,n.c,n.e,n.b,n.d,n.f,0,0,1];f(n)&&(this._transform=n)};this.me=function(){var n=
new Da;n.ue=this.ue;n.ve=this.ve;return n};this.fe=function(){this.Rd&&(this.Rd.delete(),this.Rd=null)};this.oe=function(){this.fe();return this.Rd=this.xf.makeShaderCubic(this.ue,this.ve,1/3,1/3,this._transform)}}function Ea(G,k,n,w,A,B){this.Rd=null;this.Yd=[];this.Td=[];this.addColorStop=function(D,J){if(0>D||1<D||!isFinite(D))throw"offset must be between 0 and 1 inclusively";J=h(J);var U=this.Td.indexOf(D);if(-1!==U)this.Yd[U]=J;else{for(U=0;U<this.Td.length&&!(this.Td[U]>D);U++);this.Td.splice(U,
0,D);this.Yd.splice(U,0,J)}};this.me=function(){var D=new Ea(G,k,n,w,A,B);D.Yd=this.Yd.slice();D.Td=this.Td.slice();return D};this.fe=function(){this.Rd&&(this.Rd.delete(),this.Rd=null)};this.oe=function(D){var J=[G,k,w,A];a.Matrix.mapPoints(D,J);var U=J[0],ma=J[1],ba=J[2];J=J[3];var ya=(Math.abs(D[0])+Math.abs(D[4]))/2;D=n*ya;ya*=B;this.fe();return this.Rd=a.Shader.MakeTwoPointConicalGradient([U,ma],D,[ba,J],ya,this.Yd,this.Td,a.TileMode.Clamp)}}a._testing={};var Ja={aliceblue:Float32Array.of(.941,
.973,1,1),antiquewhite:Float32Array.of(.98,.922,.843,1),aqua:Float32Array.of(0,1,1,1),aquamarine:Float32Array.of(.498,1,.831,1),azure:Float32Array.of(.941,1,1,1),beige:Float32Array.of(.961,.961,.863,1),bisque:Float32Array.of(1,.894,.769,1),black:Float32Array.of(0,0,0,1),blanchedalmond:Float32Array.of(1,.922,.804,1),blue:Float32Array.of(0,0,1,1),blueviolet:Float32Array.of(.541,.169,.886,1),brown:Float32Array.of(.647,.165,.165,1),burlywood:Float32Array.of(.871,.722,.529,1),cadetblue:Float32Array.of(.373,
.62,.627,1),chartreuse:Float32Array.of(.498,1,0,1),chocolate:Float32Array.of(.824,.412,.118,1),coral:Float32Array.of(1,.498,.314,1),cornflowerblue:Float32Array.of(.392,.584,.929,1),cornsilk:Float32Array.of(1,.973,.863,1),crimson:Float32Array.of(.863,.078,.235,1),cyan:Float32Array.of(0,1,1,1),darkblue:Float32Array.of(0,0,.545,1),darkcyan:Float32Array.of(0,.545,.545,1),darkgoldenrod:Float32Array.of(.722,.525,.043,1),darkgray:Float32Array.of(.663,.663,.663,1),darkgreen:Float32Array.of(0,.392,0,1),darkgrey:Float32Array.of(.663,
.663,.663,1),darkkhaki:Float32Array.of(.741,.718,.42,1),darkmagenta:Float32Array.of(.545,0,.545,1),darkolivegreen:Float32Array.of(.333,.42,.184,1),darkorange:Float32Array.of(1,.549,0,1),darkorchid:Float32Array.of(.6,.196,.8,1),darkred:Float32Array.of(.545,0,0,1),darksalmon:Float32Array.of(.914,.588,.478,1),darkseagreen:Float32Array.of(.561,.737,.561,1),darkslateblue:Float32Array.of(.282,.239,.545,1),darkslategray:Float32Array.of(.184,.31,.31,1),darkslategrey:Float32Array.of(.184,.31,.31,1),darkturquoise:Float32Array.of(0,
.808,.82,1),darkviolet:Float32Array.of(.58,0,.827,1),deeppink:Float32Array.of(1,.078,.576,1),deepskyblue:Float32Array.of(0,.749,1,1),dimgray:Float32Array.of(.412,.412,.412,1),dimgrey:Float32Array.of(.412,.412,.412,1),dodgerblue:Float32Array.of(.118,.565,1,1),firebrick:Float32Array.of(.698,.133,.133,1),floralwhite:Float32Array.of(1,.98,.941,1),forestgreen:Float32Array.of(.133,.545,.133,1),fuchsia:Float32Array.of(1,0,1,1),gainsboro:Float32Array.of(.863,.863,.863,1),ghostwhite:Float32Array.of(.973,.973,
1,1),gold:Float32Array.of(1,.843,0,1),goldenrod:Float32Array.of(.855,.647,.125,1),gray:Float32Array.of(.502,.502,.502,1),green:Float32Array.of(0,.502,0,1),greenyellow:Float32Array.of(.678,1,.184,1),grey:Float32Array.of(.502,.502,.502,1),honeydew:Float32Array.of(.941,1,.941,1),hotpink:Float32Array.of(1,.412,.706,1),indianred:Float32Array.of(.804,.361,.361,1),indigo:Float32Array.of(.294,0,.51,1),ivory:Float32Array.of(1,1,.941,1),khaki:Float32Array.of(.941,.902,.549,1),lavender:Float32Array.of(.902,
.902,.98,1),lavenderblush:Float32Array.of(1,.941,.961,1),lawngreen:Float32Array.of(.486,.988,0,1),lemonchiffon:Float32Array.of(1,.98,.804,1),lightblue:Float32Array.of(.678,.847,.902,1),lightcoral:Float32Array.of(.941,.502,.502,1),lightcyan:Float32Array.of(.878,1,1,1),lightgoldenrodyellow:Float32Array.of(.98,.98,.824,1),lightgray:Float32Array.of(.827,.827,.827,1),lightgreen:Float32Array.of(.565,.933,.565,1),lightgrey:Float32Array.of(.827,.827,.827,1),lightpink:Float32Array.of(1,.714,.757,1),lightsalmon:Float32Array.of(1,
.627,.478,1),lightseagreen:Float32Array.of(.125,.698,.667,1),lightskyblue:Float32Array.of(.529,.808,.98,1),lightslategray:Float32Array.of(.467,.533,.6,1),lightslategrey:Float32Array.of(.467,.533,.6,1),lightsteelblue:Float32Array.of(.69,.769,.871,1),lightyellow:Float32Array.of(1,1,.878,1),lime:Float32Array.of(0,1,0,1),limegreen:Float32Array.of(.196,.804,.196,1),linen:Float32Array.of(.98,.941,.902,1),magenta:Float32Array.of(1,0,1,1),maroon:Float32Array.of(.502,0,0,1),mediumaquamarine:Float32Array.of(.4,
.804,.667,1),mediumblue:Float32Array.of(0,0,.804,1),mediumorchid:Float32Array.of(.729,.333,.827,1),mediumpurple:Float32Array.of(.576,.439,.859,1),mediumseagreen:Float32Array.of(.235,.702,.443,1),mediumslateblue:Float32Array.of(.482,.408,.933,1),mediumspringgreen:Float32Array.of(0,.98,.604,1),mediumturquoise:Float32Array.of(.282,.82,.8,1),mediumvioletred:Float32Array.of(.78,.082,.522,1),midnightblue:Float32Array.of(.098,.098,.439,1),mintcream:Float32Array.of(.961,1,.98,1),mistyrose:Float32Array.of(1,
.894,.882,1),moccasin:Float32Array.of(1,.894,.71,1),navajowhite:Float32Array.of(1,.871,.678,1),navy:Float32Array.of(0,0,.502,1),oldlace:Float32Array.of(.992,.961,.902,1),olive:Float32Array.of(.502,.502,0,1),olivedrab:Float32Array.of(.42,.557,.137,1),orange:Float32Array.of(1,.647,0,1),orangered:Float32Array.of(1,.271,0,1),orchid:Float32Array.of(.855,.439,.839,1),palegoldenrod:Float32Array.of(.933,.91,.667,1),palegreen:Float32Array.of(.596,.984,.596,1),paleturquoise:Float32Array.of(.686,.933,.933,1),
palevioletred:Float32Array.of(.859,.439,.576,1),papayawhip:Float32Array.of(1,.937,.835,1),peachpuff:Float32Array.of(1,.855,.725,1),peru:Float32Array.of(.804,.522,.247,1),pink:Float32Array.of(1,.753,.796,1),plum:Float32Array.of(.867,.627,.867,1),powderblue:Float32Array.of(.69,.878,.902,1),purple:Float32Array.of(.502,0,.502,1),rebeccapurple:Float32Array.of(.4,.2,.6,1),red:Float32Array.of(1,0,0,1),rosybrown:Float32Array.of(.737,.561,.561,1),royalblue:Float32Array.of(.255,.412,.882,1),saddlebrown:Float32Array.of(.545,
.271,.075,1),salmon:Float32Array.of(.98,.502,.447,1),sandybrown:Float32Array.of(.957,.643,.376,1),seagreen:Float32Array.of(.18,.545,.341,1),seashell:Float32Array.of(1,.961,.933,1),sienna:Float32Array.of(.627,.322,.176,1),silver:Float32Array.of(.753,.753,.753,1),skyblue:Float32Array.of(.529,.808,.922,1),slateblue:Float32Array.of(.416,.353,.804,1),slategray:Float32Array.of(.439,.502,.565,1),slategrey:Float32Array.of(.439,.502,.565,1),snow:Float32Array.of(1,.98,.98,1),springgreen:Float32Array.of(0,1,
.498,1),steelblue:Float32Array.of(.275,.51,.706,1),tan:Float32Array.of(.824,.706,.549,1),teal:Float32Array.of(0,.502,.502,1),thistle:Float32Array.of(.847,.749,.847,1),tomato:Float32Array.of(1,.388,.278,1),transparent:Float32Array.of(0,0,0,0),turquoise:Float32Array.of(.251,.878,.816,1),violet:Float32Array.of(.933,.51,.933,1),wheat:Float32Array.of(.961,.871,.702,1),white:Float32Array.of(1,1,1,1),whitesmoke:Float32Array.of(.961,.961,.961,1),yellow:Float32Array.of(1,1,0,1),yellowgreen:Float32Array.of(.604,
.804,.196,1)};a._testing.parseColor=h;a._testing.colorToString=d;var Ka=/(italic|oblique|normal|)\s*(small-caps|normal|)\s*(bold|bolder|lighter|[1-9]00|normal|)\s*([\d\.]+)(px|pt|pc|in|cm|mm|%|em|ex|ch|rem|q)(.+)/,pa={"Noto Mono":{"*":null},monospace:{"*":null}};a._testing.parseFontString=l;a.MakeCanvas=function(G,k){return(G=a.MakeSurface(G,k))?new z(G):null};a.ImageData=function(){if(2===arguments.length){var G=arguments[0],k=arguments[1];return new C(new Uint8ClampedArray(4*G*k),G,k)}if(3===arguments.length){var n=
arguments[0];if(n.prototype.constructor!==Uint8ClampedArray)throw"bytes must be given as a Uint8ClampedArray";G=arguments[1];k=arguments[2];if(n%4)throw"bytes must be given in a multiple of 4";if(n%G)throw"bytes must divide evenly by width";if(k&&k!==n/(4*G))throw"invalid height given";return new C(n,G,n/(4*G))}throw"invalid number of arguments - takes 2 or 3, saw "+arguments.length;}})()})(t);var qa={},ra;for(ra in t)t.hasOwnProperty(ra)&&(qa[ra]=t[ra]);var sa="./this.program";
function ta(a,b){throw b;}var ua=!1,va=!1,wa=!1,za=!1;ua="object"===typeof window;va="function"===typeof importScripts;wa="object"===typeof process&&"object"===typeof process.versions&&"string"===typeof process.versions.node;za=!ua&&!wa&&!va;var Aa="",Ba,Ca,Fa,Ga,La;
if(wa)Aa=va?require("path").dirname(Aa)+"/":__dirname+"/",Ba=function(a,b){Ga||(Ga=require("fs"));La||(La=require("path"));a=La.normalize(a);return Ga.readFileSync(a,b?null:"utf8")},Fa=function(a){a=Ba(a,!0);a.buffer||(a=new Uint8Array(a));assert(a.buffer);return a},1<process.argv.length&&(sa=process.argv[1].replace(/\\/g,"/")),process.argv.slice(2),process.on("uncaughtException",function(a){if(!(a instanceof Ma))throw a;}),process.on("unhandledRejection",Na),ta=function(a){process.exit(a)},t.inspect=
function(){return"[Emscripten Module object]"};else if(za)"undefined"!=typeof read&&(Ba=function(a){return read(a)}),Fa=function(a){if("function"===typeof readbuffer)return new Uint8Array(readbuffer(a));a=read(a,"binary");assert("object"===typeof a);return a},"function"===typeof quit&&(ta=function(a){quit(a)}),"undefined"!==typeof print&&("undefined"===typeof console&&(console={}),console.log=print,console.warn=console.error="undefined"!==typeof printErr?printErr:print);else if(ua||va)va?Aa=self.location.href:
"undefined"!==typeof document&&document.currentScript&&(Aa=document.currentScript.src),_scriptDir&&(Aa=_scriptDir),0!==Aa.indexOf("blob:")?Aa=Aa.substr(0,Aa.lastIndexOf("/")+1):Aa="",Ba=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.send(null);return b.responseText},va&&(Fa=function(a){var b=new XMLHttpRequest;b.open("GET",a,!1);b.responseType="arraybuffer";b.send(null);return new Uint8Array(b.response)}),Ca=function(a,b,c){var e=new XMLHttpRequest;e.open("GET",a,!0);e.responseType="arraybuffer";
e.onload=function(){200==e.status||0==e.status&&e.response?b(e.response):c()};e.onerror=c;e.send(null)};var Oa=t.print||console.log.bind(console),Pa=t.printErr||console.warn.bind(console);for(ra in qa)qa.hasOwnProperty(ra)&&(t[ra]=qa[ra]);qa=null;t.thisProgram&&(sa=t.thisProgram);t.quit&&(ta=t.quit);var Qa=0,Sa;t.wasmBinary&&(Sa=t.wasmBinary);var noExitRuntime=t.noExitRuntime||!0;"object"!==typeof WebAssembly&&Na("no native wasm support detected");var Ta,Ua=!1;
function assert(a,b){a||Na("Assertion failed: "+b)}var Va="undefined"!==typeof TextDecoder?new TextDecoder("utf8"):void 0;
function Wa(a,b,c){var e=b+c;for(c=b;a[c]&&!(c>=e);)++c;if(16<c-b&&a.subarray&&Va)return Va.decode(a.subarray(b,c));for(e="";b<c;){var g=a[b++];if(g&128){var m=a[b++]&63;if(192==(g&224))e+=String.fromCharCode((g&31)<<6|m);else{var p=a[b++]&63;g=224==(g&240)?(g&15)<<12|m<<6|p:(g&7)<<18|m<<12|p<<6|a[b++]&63;65536>g?e+=String.fromCharCode(g):(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023))}}else e+=String.fromCharCode(g)}return e}function Xa(a,b){return a?Wa(F,a,b):""}
function oa(a,b,c,e){if(!(0<e))return 0;var g=c;e=c+e-1;for(var m=0;m<a.length;++m){var p=a.charCodeAt(m);if(55296<=p&&57343>=p){var r=a.charCodeAt(++m);p=65536+((p&1023)<<10)|r&1023}if(127>=p){if(c>=e)break;b[c++]=p}else{if(2047>=p){if(c+1>=e)break;b[c++]=192|p>>6}else{if(65535>=p){if(c+2>=e)break;b[c++]=224|p>>12}else{if(c+3>=e)break;b[c++]=240|p>>18;b[c++]=128|p>>12&63}b[c++]=128|p>>6&63}b[c++]=128|p&63}}b[c]=0;return c-g}
function ka(a){for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);55296<=e&&57343>=e&&(e=65536+((e&1023)<<10)|a.charCodeAt(++c)&1023);127>=e?++b:b=2047>=e?b+2:65535>=e?b+3:b+4}return b}var Ya="undefined"!==typeof TextDecoder?new TextDecoder("utf-16le"):void 0;function Za(a,b){var c=a>>1;for(var e=c+b/2;!(c>=e)&&$a[c];)++c;c<<=1;if(32<c-a&&Ya)return Ya.decode(F.subarray(a,c));c="";for(e=0;!(e>=b/2);++e){var g=ab[a+2*e>>1];if(0==g)break;c+=String.fromCharCode(g)}return c}
function bb(a,b,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var e=b;c=c<2*a.length?c/2:a.length;for(var g=0;g<c;++g)ab[b>>1]=a.charCodeAt(g),b+=2;ab[b>>1]=0;return b-e}function cb(a){return 2*a.length}function db(a,b){for(var c=0,e="";!(c>=b/4);){var g=N[a+4*c>>2];if(0==g)break;++c;65536<=g?(g-=65536,e+=String.fromCharCode(55296|g>>10,56320|g&1023)):e+=String.fromCharCode(g)}return e}
function eb(a,b,c){void 0===c&&(c=2147483647);if(4>c)return 0;var e=b;c=e+c-4;for(var g=0;g<a.length;++g){var m=a.charCodeAt(g);if(55296<=m&&57343>=m){var p=a.charCodeAt(++g);m=65536+((m&1023)<<10)|p&1023}N[b>>2]=m;b+=4;if(b+4>c)break}N[b>>2]=0;return b-e}function fb(a){for(var b=0,c=0;c<a.length;++c){var e=a.charCodeAt(c);55296<=e&&57343>=e&&++c;b+=4}return b}var kb,lb,F,ab,$a,N,mb,Q,nb;
function ob(){var a=Ta.buffer;kb=a;t.HEAP8=lb=new Int8Array(a);t.HEAP16=ab=new Int16Array(a);t.HEAP32=N=new Int32Array(a);t.HEAPU8=F=new Uint8Array(a);t.HEAPU16=$a=new Uint16Array(a);t.HEAPU32=mb=new Uint32Array(a);t.HEAPF32=Q=new Float32Array(a);t.HEAPF64=nb=new Float64Array(a)}var pb,qb=[],rb=[],sb=[];function tb(){var a=t.preRun.shift();qb.unshift(a)}var ub=0,vb=null,wb=null;t.preloadedImages={};t.preloadedAudios={};
function Na(a){if(t.onAbort)t.onAbort(a);Pa(a);Ua=!0;a=new WebAssembly.RuntimeError("abort("+a+"). Build with -s ASSERTIONS=1 for more info.");ca(a);throw a;}function xb(){return yb.startsWith("data:application/octet-stream;base64,")}var yb="canvaskit.wasm";if(!xb()){var zb=yb;yb=t.locateFile?t.locateFile(zb,Aa):Aa+zb}function Bb(){var a=yb;try{if(a==yb&&Sa)return new Uint8Array(Sa);if(Fa)return Fa(a);throw"both async and sync fetching of the wasm failed";}catch(b){Na(b)}}
function Cb(){if(!Sa&&(ua||va)){if("function"===typeof fetch&&!yb.startsWith("file://"))return fetch(yb,{credentials:"same-origin"}).then(function(a){if(!a.ok)throw"failed to load wasm binary file at '"+yb+"'";return a.arrayBuffer()}).catch(function(){return Bb()});if(Ca)return new Promise(function(a,b){Ca(yb,function(c){a(new Uint8Array(c))},b)})}return Promise.resolve().then(function(){return Bb()})}
function Db(a){for(;0<a.length;){var b=a.shift();if("function"==typeof b)b(t);else{var c=b.sg;"number"===typeof c?void 0===b.cf?pb.get(c)():pb.get(c)(b.cf):c(void 0===b.cf?null:b.cf)}}}var Eb={},Fb=[null,[],[]],Gb={},Hb={};function Ib(a){for(;a.length;){var b=a.pop();a.pop()(b)}}function Jb(a){return this.fromWireType(mb[a>>2])}var Kb={},Lb={},Mb={};function Nb(a){if(void 0===a)return"_unknown";a=a.replace(/[^a-zA-Z0-9_]/g,"$");var b=a.charCodeAt(0);return 48<=b&&57>=b?"_"+a:a}
function Ob(a,b){a=Nb(a);return(new Function("body","return function "+a+'() {\n    "use strict";    return body.apply(this, arguments);\n};\n'))(b)}function Pb(a){var b=Error,c=Ob(a,function(e){this.name=a;this.message=e;e=Error(e).stack;void 0!==e&&(this.stack=this.toString()+"\n"+e.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(b.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}
var Qb=void 0;function Rb(a){throw new Qb(a);}function Sb(a,b,c){function e(r){r=c(r);r.length!==a.length&&Rb("Mismatched type converter count");for(var x=0;x<a.length;++x)Tb(a[x],r[x])}a.forEach(function(r){Mb[r]=b});var g=Array(b.length),m=[],p=0;b.forEach(function(r,x){Lb.hasOwnProperty(r)?g[x]=Lb[r]:(m.push(r),Kb.hasOwnProperty(r)||(Kb[r]=[]),Kb[r].push(function(){g[x]=Lb[r];++p;p===m.length&&e(g)}))});0===m.length&&e(g)}
function Ub(a){switch(a){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+a);}}var Vb=void 0;function Wb(a){for(var b="";F[a];)b+=Vb[F[a++]];return b}var Xb=void 0;function S(a){throw new Xb(a);}
function Tb(a,b,c){c=c||{};if(!("argPackAdvance"in b))throw new TypeError("registerType registeredInstance requires argPackAdvance");var e=b.name;a||S('type "'+e+'" must have a positive integer typeid pointer');if(Lb.hasOwnProperty(a)){if(c.Sf)return;S("Cannot register type '"+e+"' twice")}Lb[a]=b;delete Mb[a];Kb.hasOwnProperty(a)&&(b=Kb[a],delete Kb[a],b.forEach(function(g){g()}))}function Yb(a){S(a.Gd.Qd.Kd.name+" instance already deleted")}var Zb=!1;function $b(){}
function ac(a){--a.count.value;0===a.count.value&&(a.Xd?a.ae.ee(a.Xd):a.Qd.Kd.ee(a.Md))}function bc(a){if("undefined"===typeof FinalizationGroup)return bc=function(b){return b},a;Zb=new FinalizationGroup(function(b){for(var c=b.next();!c.done;c=b.next())c=c.value,c.Md?ac(c):console.warn("object already deleted: "+c.Md)});bc=function(b){Zb.register(b,b.Gd,b.Gd);return b};$b=function(b){Zb.unregister(b.Gd)};return bc(a)}var kc=void 0,lc=[];
function mc(){for(;lc.length;){var a=lc.pop();a.Gd.ze=!1;a["delete"]()}}function nc(){}var oc={};function pc(a,b,c){if(void 0===a[b].Sd){var e=a[b];a[b]=function(){a[b].Sd.hasOwnProperty(arguments.length)||S("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+a[b].Sd+")!");return a[b].Sd[arguments.length].apply(this,arguments)};a[b].Sd=[];a[b].Sd[e.xe]=e}}
function qc(a,b,c){t.hasOwnProperty(a)?((void 0===c||void 0!==t[a].Sd&&void 0!==t[a].Sd[c])&&S("Cannot register public name '"+a+"' twice"),pc(t,a,a),t.hasOwnProperty(c)&&S("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),t[a].Sd[c]=b):(t[a]=b,void 0!==c&&(t[a].ug=c))}function rc(a,b,c,e,g,m,p,r){this.name=a;this.constructor=b;this.Ae=c;this.ee=e;this.ce=g;this.Lf=m;this.Me=p;this.Ff=r;this.ag=[]}
function sc(a,b,c){for(;b!==c;)b.Me||S("Expected null or instance of "+c.name+", got an instance of "+b.name),a=b.Me(a),b=b.ce;return a}function tc(a,b){if(null===b)return this.ef&&S("null is not a valid "+this.name),0;b.Gd||S('Cannot pass "'+uc(b)+'" as a '+this.name);b.Gd.Md||S("Cannot pass deleted object as a pointer of type "+this.name);return sc(b.Gd.Md,b.Gd.Qd.Kd,this.Kd)}
function vc(a,b){if(null===b){this.ef&&S("null is not a valid "+this.name);if(this.Re){var c=this.ff();null!==a&&a.push(this.ee,c);return c}return 0}b.Gd||S('Cannot pass "'+uc(b)+'" as a '+this.name);b.Gd.Md||S("Cannot pass deleted object as a pointer of type "+this.name);!this.Qe&&b.Gd.Qd.Qe&&S("Cannot convert argument of type "+(b.Gd.ae?b.Gd.ae.name:b.Gd.Qd.name)+" to parameter type "+this.name);c=sc(b.Gd.Md,b.Gd.Qd.Kd,this.Kd);if(this.Re)switch(void 0===b.Gd.Xd&&S("Passing raw pointer to smart pointer is illegal"),
this.gg){case 0:b.Gd.ae===this?c=b.Gd.Xd:S("Cannot convert argument of type "+(b.Gd.ae?b.Gd.ae.name:b.Gd.Qd.name)+" to parameter type "+this.name);break;case 1:c=b.Gd.Xd;break;case 2:if(b.Gd.ae===this)c=b.Gd.Xd;else{var e=b.clone();c=this.bg(c,wc(function(){e["delete"]()}));null!==a&&a.push(this.ee,c)}break;default:S("Unsupporting sharing policy")}return c}
function xc(a,b){if(null===b)return this.ef&&S("null is not a valid "+this.name),0;b.Gd||S('Cannot pass "'+uc(b)+'" as a '+this.name);b.Gd.Md||S("Cannot pass deleted object as a pointer of type "+this.name);b.Gd.Qd.Qe&&S("Cannot convert argument of type "+b.Gd.Qd.name+" to parameter type "+this.name);return sc(b.Gd.Md,b.Gd.Qd.Kd,this.Kd)}function yc(a,b,c){if(b===c)return a;if(void 0===c.ce)return null;a=yc(a,b,c.ce);return null===a?null:c.Ff(a)}var zc={};
function Ac(a,b){for(void 0===b&&S("ptr should not be undefined");a.ce;)b=a.Me(b),a=a.ce;return zc[b]}function Bc(a,b){b.Qd&&b.Md||Rb("makeClassHandle requires ptr and ptrType");!!b.ae!==!!b.Xd&&Rb("Both smartPtrType and smartPtr must be specified");b.count={value:1};return bc(Object.create(a,{Gd:{value:b}}))}
function Cc(a,b,c,e,g,m,p,r,x,v,E){this.name=a;this.Kd=b;this.ef=c;this.Qe=e;this.Re=g;this.$f=m;this.gg=p;this.tf=r;this.ff=x;this.bg=v;this.ee=E;g||void 0!==b.ce?this.toWireType=vc:(this.toWireType=e?tc:xc,this.$d=null)}function Dc(a,b,c){t.hasOwnProperty(a)||Rb("Replacing nonexistant public symbol");void 0!==t[a].Sd&&void 0!==c?t[a].Sd[c]=b:(t[a]=b,t[a].xe=c)}
function Ec(a,b){var c=[];return function(){c.length=arguments.length;for(var e=0;e<arguments.length;e++)c[e]=arguments[e];a.includes("j")?(e=t["dynCall_"+a],e=c&&c.length?e.apply(null,[b].concat(c)):e.call(null,b)):e=pb.get(b).apply(null,c);return e}}function Fc(a,b){a=Wb(a);var c=a.includes("j")?Ec(a,b):pb.get(b);"function"!==typeof c&&S("unknown function pointer with signature "+a+": "+b);return c}var Gc=void 0;function Hc(a){a=Ic(a);var b=Wb(a);Jc(a);return b}
function Kc(a,b){function c(m){g[m]||Lb[m]||(Mb[m]?Mb[m].forEach(c):(e.push(m),g[m]=!0))}var e=[],g={};b.forEach(c);throw new Gc(a+": "+e.map(Hc).join([", "]));}function Lc(a){var b=Function;if(!(b instanceof Function))throw new TypeError("new_ called with constructor type "+typeof b+" which is not a function");var c=Ob(b.name||"unknownFunctionName",function(){});c.prototype=b.prototype;c=new c;a=b.apply(c,a);return a instanceof Object?a:c}
function Uc(a,b,c,e,g){var m=b.length;2>m&&S("argTypes array size mismatch! Must at least get return value and 'this' types!");var p=null!==b[1]&&null!==c,r=!1;for(c=1;c<b.length;++c)if(null!==b[c]&&void 0===b[c].$d){r=!0;break}var x="void"!==b[0].name,v="",E="";for(c=0;c<m-2;++c)v+=(0!==c?", ":"")+"arg"+c,E+=(0!==c?", ":"")+"arg"+c+"Wired";a="return function "+Nb(a)+"("+v+") {\nif (arguments.length !== "+(m-2)+") {\nthrowBindingError('function "+a+" called with ' + arguments.length + ' arguments, expected "+
(m-2)+" args!');\n}\n";r&&(a+="var destructors = [];\n");var I=r?"destructors":"null";v="throwBindingError invoker fn runDestructors retType classParam".split(" ");e=[S,e,g,Ib,b[0],b[1]];p&&(a+="var thisWired = classParam.toWireType("+I+", this);\n");for(c=0;c<m-2;++c)a+="var arg"+c+"Wired = argType"+c+".toWireType("+I+", arg"+c+"); // "+b[c+2].name+"\n",v.push("argType"+c),e.push(b[c+2]);p&&(E="thisWired"+(0<E.length?", ":"")+E);a+=(x?"var rv = ":"")+"invoker(fn"+(0<E.length?", ":"")+E+");\n";if(r)a+=
"runDestructors(destructors);\n";else for(c=p?1:2;c<b.length;++c)m=1===c?"thisWired":"arg"+(c-2)+"Wired",null!==b[c].$d&&(a+=m+"_dtor("+m+"); // "+b[c].name+"\n",v.push(m+"_dtor"),e.push(b[c].$d));x&&(a+="var ret = retType.fromWireType(rv);\nreturn ret;\n");v.push(a+"}\n");return Lc(v).apply(null,e)}function Vc(a,b){for(var c=[],e=0;e<a;e++)c.push(N[(b>>2)+e]);return c}var Wc=[],Xc=[{},{value:void 0},{value:null},{value:!0},{value:!1}];
function Yc(a){4<a&&0===--Xc[a].gf&&(Xc[a]=void 0,Wc.push(a))}function wc(a){switch(a){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var b=Wc.length?Wc.pop():Xc.length;Xc[b]={gf:1,value:a};return b}}
function Zc(a,b,c){switch(b){case 0:return function(e){return this.fromWireType((c?lb:F)[e])};case 1:return function(e){return this.fromWireType((c?ab:$a)[e>>1])};case 2:return function(e){return this.fromWireType((c?N:mb)[e>>2])};default:throw new TypeError("Unknown integer type: "+a);}}function $c(a,b){var c=Lb[a];void 0===c&&S(b+" has unknown type "+Hc(a));return c}function uc(a){if(null===a)return"null";var b=typeof a;return"object"===b||"array"===b||"function"===b?a.toString():""+a}
function ad(a,b){switch(b){case 2:return function(c){return this.fromWireType(Q[c>>2])};case 3:return function(c){return this.fromWireType(nb[c>>3])};default:throw new TypeError("Unknown float type: "+a);}}
function bd(a,b,c){switch(b){case 0:return c?function(e){return lb[e]}:function(e){return F[e]};case 1:return c?function(e){return ab[e>>1]}:function(e){return $a[e>>1]};case 2:return c?function(e){return N[e>>2]}:function(e){return mb[e>>2]};default:throw new TypeError("Unknown integer type: "+a);}}var cd={};function dd(a){var b=cd[a];return void 0===b?Wb(a):b}var ed=[];function fd(a){a||S("Cannot use deleted val. handle = "+a);return Xc[a].value}
function gd(a){var b=ed.length;ed.push(a);return b}function hd(a,b){for(var c=Array(a),e=0;e<a;++e)c[e]=$c(N[(b>>2)+e],"parameter "+e);return c}var jd;wa?jd=function(){var a=process.hrtime();return 1E3*a[0]+a[1]/1E6}:"undefined"!==typeof dateNow?jd=dateNow:jd=function(){return performance.now()};
function kd(a){var b=a.getExtension("ANGLE_instanced_arrays");b&&(a.vertexAttribDivisor=function(c,e){b.vertexAttribDivisorANGLE(c,e)},a.drawArraysInstanced=function(c,e,g,m){b.drawArraysInstancedANGLE(c,e,g,m)},a.drawElementsInstanced=function(c,e,g,m,p){b.drawElementsInstancedANGLE(c,e,g,m,p)})}
function ld(a){var b=a.getExtension("OES_vertex_array_object");b&&(a.createVertexArray=function(){return b.createVertexArrayOES()},a.deleteVertexArray=function(c){b.deleteVertexArrayOES(c)},a.bindVertexArray=function(c){b.bindVertexArrayOES(c)},a.isVertexArray=function(c){return b.isVertexArrayOES(c)})}function md(a){var b=a.getExtension("WEBGL_draw_buffers");b&&(a.drawBuffers=function(c,e){b.drawBuffersWEBGL(c,e)})}
var nd=1,od=[],pd=[],qd=[],rd=[],sd=[],td=[],ud=[],ja=[],vd=[],wd=[],xd={},yd={},zd=4;function V(a){Ad||(Ad=a)}function Bd(a){for(var b=nd++,c=a.length;c<b;c++)a[c]=null;return b}function fa(a,b){a.rf||(a.rf=a.getContext,a.getContext=function(e,g){g=a.rf(e,g);return"webgl"==e==g instanceof WebGLRenderingContext?g:null});var c=1<b.majorVersion?a.getContext("webgl2",b):a.getContext("webgl",b);return c?Cd(c,b):0}
function Cd(a,b){var c=Bd(ja),e={Rf:c,attributes:b,version:b.majorVersion,Ce:a};a.canvas&&(a.canvas.jf=e);ja[c]=e;("undefined"===typeof b.Gf||b.Gf)&&Dd(e);return c}function ia(a){u=ja[a];t.rg=W=u&&u.Ce;return!(a&&!W)}
function Dd(a){a||(a=u);if(!a.Tf){a.Tf=!0;var b=a.Ce;kd(b);ld(b);md(b);b.nf=b.getExtension("WEBGL_draw_instanced_base_vertex_base_instance");b.sf=b.getExtension("WEBGL_multi_draw_instanced_base_vertex_base_instance");2<=a.version&&(b.pf=b.getExtension("EXT_disjoint_timer_query_webgl2"));if(2>a.version||!b.pf)b.pf=b.getExtension("EXT_disjoint_timer_query");b.tg=b.getExtension("WEBGL_multi_draw");(b.getSupportedExtensions()||[]).forEach(function(c){c.includes("lose_context")||c.includes("debug")||b.getExtension(c)})}}
var Ad,u,Ed=[];function Id(a,b,c,e){for(var g=0;g<a;g++){var m=W[c](),p=m&&Bd(e);m?(m.name=p,e[p]=m):V(1282);N[b+4*g>>2]=p}}
function Jd(a,b){if(b){var c=void 0;switch(a){case 36346:c=1;break;case 36344:return;case 34814:case 36345:c=0;break;case 34466:var e=W.getParameter(34467);c=e?e.length:0;break;case 33309:if(2>u.version){V(1282);return}c=2*(W.getSupportedExtensions()||[]).length;break;case 33307:case 33308:if(2>u.version){V(1280);return}c=33307==a?3:0}if(void 0===c)switch(e=W.getParameter(a),typeof e){case "number":c=e;break;case "boolean":c=e?1:0;break;case "string":V(1280);return;case "object":if(null===e)switch(a){case 34964:case 35725:case 34965:case 36006:case 36007:case 32873:case 34229:case 36662:case 36663:case 35053:case 35055:case 36010:case 35097:case 35869:case 32874:case 36389:case 35983:case 35368:case 34068:c=
0;break;default:V(1280);return}else{if(e instanceof Float32Array||e instanceof Uint32Array||e instanceof Int32Array||e instanceof Array){for(a=0;a<e.length;++a)N[b+4*a>>2]=e[a];return}try{c=e.name|0}catch(g){V(1280);Pa("GL_INVALID_ENUM in glGet0v: Unknown object returned from WebGL getParameter("+a+")! (error: "+g+")");return}}break;default:V(1280);Pa("GL_INVALID_ENUM in glGet0v: Native code calling glGet0v("+a+") and it returns "+e+" of type "+typeof e+"!");return}N[b>>2]=c}else V(1281)}
function Kd(a){var b=ka(a)+1,c=Ld(b);oa(a,F,c,b);return c}function Md(a){a-=5120;return 0==a?lb:1==a?F:2==a?ab:4==a?N:6==a?Q:5==a||28922==a||28520==a||30779==a||30782==a?mb:$a}function Nd(a,b,c,e,g){a=Md(a);var m=31-Math.clz32(a.BYTES_PER_ELEMENT),p=zd;return a.subarray(g>>m,g+e*(c*({5:3,6:4,8:2,29502:3,29504:4,26917:2,26918:2,29846:3,29847:4}[b-6402]||1)*(1<<m)+p-1&-p)>>m)}function Y(a){var b=W.Df,c=b.Ye[a];0<=c&&(b.Ye[a]=c=W.getUniformLocation(b,b.uf[a]+(0<c?"["+c+"]":"")));return c}
var Od=[],Pd=[],Qd={};function Rd(){if(!Sd){var a={USER:"web_user",LOGNAME:"web_user",PATH:"/",PWD:"/",HOME:"/home/web_user",LANG:("object"===typeof navigator&&navigator.languages&&navigator.languages[0]||"C").replace("-","_")+".UTF-8",_:sa||"./this.program"},b;for(b in Qd)a[b]=Qd[b];var c=[];for(b in a)c.push(b+"="+a[b]);Sd=c}return Sd}var Sd;function Td(a){return 0===a%4&&(0!==a%100||0===a%400)}function Ud(a,b){for(var c=0,e=0;e<=b;c+=a[e++]);return c}
var Vd=[31,29,31,30,31,30,31,31,30,31,30,31],Wd=[31,28,31,30,31,30,31,31,30,31,30,31];function Xd(a,b){for(a=new Date(a.getTime());0<b;){var c=a.getMonth(),e=(Td(a.getFullYear())?Vd:Wd)[c];if(b>e-a.getDate())b-=e-a.getDate()+1,a.setDate(1),11>c?a.setMonth(c+1):(a.setMonth(0),a.setFullYear(a.getFullYear()+1));else{a.setDate(a.getDate()+b);break}}return a}
function Yd(a,b,c,e){function g(y,K,T){for(y="number"===typeof y?y.toString():y||"";y.length<K;)y=T[0]+y;return y}function m(y,K){return g(y,K,"0")}function p(y,K){function T(X){return 0>X?-1:0<X?1:0}var Z;0===(Z=T(y.getFullYear()-K.getFullYear()))&&0===(Z=T(y.getMonth()-K.getMonth()))&&(Z=T(y.getDate()-K.getDate()));return Z}function r(y){switch(y.getDay()){case 0:return new Date(y.getFullYear()-1,11,29);case 1:return y;case 2:return new Date(y.getFullYear(),0,3);case 3:return new Date(y.getFullYear(),
0,2);case 4:return new Date(y.getFullYear(),0,1);case 5:return new Date(y.getFullYear()-1,11,31);case 6:return new Date(y.getFullYear()-1,11,30)}}function x(y){y=Xd(new Date(y.Vd+1900,0,1),y.Xe);var K=new Date(y.getFullYear()+1,0,4),T=r(new Date(y.getFullYear(),0,4));K=r(K);return 0>=p(T,y)?0>=p(K,y)?y.getFullYear()+1:y.getFullYear():y.getFullYear()-1}var v=N[e+40>>2];e={og:N[e>>2],ng:N[e+4>>2],Ve:N[e+8>>2],Le:N[e+12>>2],Be:N[e+16>>2],Vd:N[e+20>>2],We:N[e+24>>2],Xe:N[e+28>>2],xg:N[e+32>>2],mg:N[e+
36>>2],pg:v?Xa(v):""};c=Xa(c);v={"%c":"%a %b %d %H:%M:%S %Y","%D":"%m/%d/%y","%F":"%Y-%m-%d","%h":"%b","%r":"%I:%M:%S %p","%R":"%H:%M","%T":"%H:%M:%S","%x":"%m/%d/%y","%X":"%H:%M:%S","%Ec":"%c","%EC":"%C","%Ex":"%m/%d/%y","%EX":"%H:%M:%S","%Ey":"%y","%EY":"%Y","%Od":"%d","%Oe":"%e","%OH":"%H","%OI":"%I","%Om":"%m","%OM":"%M","%OS":"%S","%Ou":"%u","%OU":"%U","%OV":"%V","%Ow":"%w","%OW":"%W","%Oy":"%y"};for(var E in v)c=c.replace(new RegExp(E,"g"),v[E]);var I="Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "),
L="January February March April May June July August September October November December".split(" ");v={"%a":function(y){return I[y.We].substring(0,3)},"%A":function(y){return I[y.We]},"%b":function(y){return L[y.Be].substring(0,3)},"%B":function(y){return L[y.Be]},"%C":function(y){return m((y.Vd+1900)/100|0,2)},"%d":function(y){return m(y.Le,2)},"%e":function(y){return g(y.Le,2," ")},"%g":function(y){return x(y).toString().substring(2)},"%G":function(y){return x(y)},"%H":function(y){return m(y.Ve,
2)},"%I":function(y){y=y.Ve;0==y?y=12:12<y&&(y-=12);return m(y,2)},"%j":function(y){return m(y.Le+Ud(Td(y.Vd+1900)?Vd:Wd,y.Be-1),3)},"%m":function(y){return m(y.Be+1,2)},"%M":function(y){return m(y.ng,2)},"%n":function(){return"\n"},"%p":function(y){return 0<=y.Ve&&12>y.Ve?"AM":"PM"},"%S":function(y){return m(y.og,2)},"%t":function(){return"\t"},"%u":function(y){return y.We||7},"%U":function(y){var K=new Date(y.Vd+1900,0,1),T=0===K.getDay()?K:Xd(K,7-K.getDay());y=new Date(y.Vd+1900,y.Be,y.Le);return 0>
p(T,y)?m(Math.ceil((31-T.getDate()+(Ud(Td(y.getFullYear())?Vd:Wd,y.getMonth()-1)-31)+y.getDate())/7),2):0===p(T,K)?"01":"00"},"%V":function(y){var K=new Date(y.Vd+1901,0,4),T=r(new Date(y.Vd+1900,0,4));K=r(K);var Z=Xd(new Date(y.Vd+1900,0,1),y.Xe);return 0>p(Z,T)?"53":0>=p(K,Z)?"01":m(Math.ceil((T.getFullYear()<y.Vd+1900?y.Xe+32-T.getDate():y.Xe+1-T.getDate())/7),2)},"%w":function(y){return y.We},"%W":function(y){var K=new Date(y.Vd,0,1),T=1===K.getDay()?K:Xd(K,0===K.getDay()?1:7-K.getDay()+1);y=
new Date(y.Vd+1900,y.Be,y.Le);return 0>p(T,y)?m(Math.ceil((31-T.getDate()+(Ud(Td(y.getFullYear())?Vd:Wd,y.getMonth()-1)-31)+y.getDate())/7),2):0===p(T,K)?"01":"00"},"%y":function(y){return(y.Vd+1900).toString().substring(2)},"%Y":function(y){return y.Vd+1900},"%z":function(y){y=y.mg;var K=0<=y;y=Math.abs(y)/60;return(K?"+":"-")+String("0000"+(y/60*100+y%60)).slice(-4)},"%Z":function(y){return y.pg},"%%":function(){return"%"}};for(E in v)c.includes(E)&&(c=c.replace(new RegExp(E,"g"),v[E](e)));E=Zd(c);
if(E.length>b)return 0;lb.set(E,a);return E.length-1}Qb=t.InternalError=Pb("InternalError");for(var $d=Array(256),ae=0;256>ae;++ae)$d[ae]=String.fromCharCode(ae);Vb=$d;Xb=t.BindingError=Pb("BindingError");nc.prototype.isAliasOf=function(a){if(!(this instanceof nc&&a instanceof nc))return!1;var b=this.Gd.Qd.Kd,c=this.Gd.Md,e=a.Gd.Qd.Kd;for(a=a.Gd.Md;b.ce;)c=b.Me(c),b=b.ce;for(;e.ce;)a=e.Me(a),e=e.ce;return b===e&&c===a};
nc.prototype.clone=function(){this.Gd.Md||Yb(this);if(this.Gd.Ke)return this.Gd.count.value+=1,this;var a=bc,b=Object,c=b.create,e=Object.getPrototypeOf(this),g=this.Gd;a=a(c.call(b,e,{Gd:{value:{count:g.count,ze:g.ze,Ke:g.Ke,Md:g.Md,Qd:g.Qd,Xd:g.Xd,ae:g.ae}}}));a.Gd.count.value+=1;a.Gd.ze=!1;return a};nc.prototype["delete"]=function(){this.Gd.Md||Yb(this);this.Gd.ze&&!this.Gd.Ke&&S("Object already scheduled for deletion");$b(this);ac(this.Gd);this.Gd.Ke||(this.Gd.Xd=void 0,this.Gd.Md=void 0)};
nc.prototype.isDeleted=function(){return!this.Gd.Md};nc.prototype.deleteLater=function(){this.Gd.Md||Yb(this);this.Gd.ze&&!this.Gd.Ke&&S("Object already scheduled for deletion");lc.push(this);1===lc.length&&kc&&kc(mc);this.Gd.ze=!0;return this};Cc.prototype.Mf=function(a){this.tf&&(a=this.tf(a));return a};Cc.prototype.mf=function(a){this.ee&&this.ee(a)};Cc.prototype.argPackAdvance=8;Cc.prototype.readValueFromPointer=Jb;Cc.prototype.deleteObject=function(a){if(null!==a)a["delete"]()};
Cc.prototype.fromWireType=function(a){function b(){return this.Re?Bc(this.Kd.Ae,{Qd:this.$f,Md:c,ae:this,Xd:a}):Bc(this.Kd.Ae,{Qd:this,Md:a})}var c=this.Mf(a);if(!c)return this.mf(a),null;var e=Ac(this.Kd,c);if(void 0!==e){if(0===e.Gd.count.value)return e.Gd.Md=c,e.Gd.Xd=a,e.clone();e=e.clone();this.mf(a);return e}e=this.Kd.Lf(c);e=oc[e];if(!e)return b.call(this);e=this.Qe?e.Bf:e.pointerType;var g=yc(c,this.Kd,e.Kd);return null===g?b.call(this):this.Re?Bc(e.Kd.Ae,{Qd:e,Md:g,ae:this,Xd:a}):Bc(e.Kd.Ae,
{Qd:e,Md:g})};t.getInheritedInstanceCount=function(){return Object.keys(zc).length};t.getLiveInheritedInstances=function(){var a=[],b;for(b in zc)zc.hasOwnProperty(b)&&a.push(zc[b]);return a};t.flushPendingDeletes=mc;t.setDelayFunction=function(a){kc=a;lc.length&&kc&&kc(mc)};Gc=t.UnboundTypeError=Pb("UnboundTypeError");t.count_emval_handles=function(){for(var a=0,b=5;b<Xc.length;++b)void 0!==Xc[b]&&++a;return a};
t.get_first_emval=function(){for(var a=5;a<Xc.length;++a)if(void 0!==Xc[a])return Xc[a];return null};for(var W,be=0;32>be;++be)Ed.push(Array(be));var ce=new Float32Array(288);for(be=0;288>be;++be)Od[be]=ce.subarray(0,be+1);var de=new Int32Array(288);for(be=0;288>be;++be)Pd[be]=de.subarray(0,be+1);function Zd(a){var b=Array(ka(a)+1);oa(a,b,0,b.length);return b}
var xe={F:function(){return 0},xb:function(){},zb:function(){return 0},vb:function(a,b,c,e,g,m){m<<=12;0!==(e&16)&&0!==a%65536?b=-28:0!==(e&32)?(a=ee(65536,b))?(fe(a,0,b),Eb[a]={Yf:a,Xf:b,Af:!0,fd:g,vg:c,flags:e,offset:m},b=a):b=-48:b=-52;return b},ub:function(a,b){if(-1===(a|0)||0===b)a=-28;else{var c=Eb[a];c&&b===c.Xf&&(Eb[a]=null,c.Af&&Jc(c.Yf));a=0}return a},Bb:function(){},wb:function(){},E:function(a){var b=Hb[a];delete Hb[a];var c=b.ff,e=b.ee,g=b.qf,m=g.map(function(p){return p.Qf}).concat(g.map(function(p){return p.eg}));
Sb([a],m,function(p){var r={};g.forEach(function(x,v){var E=p[v],I=x.Of,L=x.Pf,y=p[v+g.length],K=x.dg,T=x.fg;r[x.Hf]={read:function(Z){return E.fromWireType(I(L,Z))},write:function(Z,X){var la=[];K(T,Z,y.toWireType(la,X));Ib(la)}}});return[{name:b.name,fromWireType:function(x){var v={},E;for(E in r)v[E]=r[E].read(x);e(x);return v},toWireType:function(x,v){for(var E in r)if(!(E in v))throw new TypeError('Missing field:  "'+E+'"');var I=c();for(E in r)r[E].write(I,v[E]);null!==x&&x.push(e,I);return I},
argPackAdvance:8,readValueFromPointer:Jb,$d:e}]})},nb:function(){},Db:function(a,b,c,e,g){var m=Ub(c);b=Wb(b);Tb(a,{name:b,fromWireType:function(p){return!!p},toWireType:function(p,r){return r?e:g},argPackAdvance:8,readValueFromPointer:function(p){if(1===c)var r=lb;else if(2===c)r=ab;else if(4===c)r=N;else throw new TypeError("Unknown boolean type size: "+b);return this.fromWireType(r[p>>m])},$d:null})},k:function(a,b,c,e,g,m,p,r,x,v,E,I,L){E=Wb(E);m=Fc(g,m);r&&(r=Fc(p,r));v&&(v=Fc(x,v));L=Fc(I,L);
var y=Nb(E);qc(y,function(){Kc("Cannot construct "+E+" due to unbound types",[e])});Sb([a,b,c],e?[e]:[],function(K){K=K[0];if(e){var T=K.Kd;var Z=T.Ae}else Z=nc.prototype;K=Ob(y,function(){if(Object.getPrototypeOf(this)!==X)throw new Xb("Use 'new' to construct "+E);if(void 0===la.ie)throw new Xb(E+" has no accessible constructor");var ib=la.ie[arguments.length];if(void 0===ib)throw new Xb("Tried to invoke ctor of "+E+" with invalid number of parameters ("+arguments.length+") - expected ("+Object.keys(la.ie).toString()+
") parameters instead!");return ib.apply(this,arguments)});var X=Object.create(Z,{constructor:{value:K}});K.prototype=X;var la=new rc(E,K,X,L,T,m,r,v);T=new Cc(E,la,!0,!1,!1);Z=new Cc(E+"*",la,!1,!1,!1);var hb=new Cc(E+" const*",la,!1,!0,!1);oc[a]={pointerType:Z,Bf:hb};Dc(y,K);return[T,Z,hb]})},f:function(a,b,c,e,g,m,p){var r=Vc(c,e);b=Wb(b);m=Fc(g,m);Sb([],[a],function(x){function v(){Kc("Cannot call "+E+" due to unbound types",r)}x=x[0];var E=x.name+"."+b,I=x.Kd.constructor;void 0===I[b]?(v.xe=
c-1,I[b]=v):(pc(I,b,E),I[b].Sd[c-1]=v);Sb([],r,function(L){L=[L[0],null].concat(L.slice(1));L=Uc(E,L,null,m,p);void 0===I[b].Sd?(L.xe=c-1,I[b]=L):I[b].Sd[c-1]=L;return[]});return[]})},t:function(a,b,c,e,g,m){assert(0<b);var p=Vc(b,c);g=Fc(e,g);var r=[m],x=[];Sb([],[a],function(v){v=v[0];var E="constructor "+v.name;void 0===v.Kd.ie&&(v.Kd.ie=[]);if(void 0!==v.Kd.ie[b-1])throw new Xb("Cannot register multiple constructors with identical number of parameters ("+(b-1)+") for class '"+v.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");
v.Kd.ie[b-1]=function(){Kc("Cannot construct "+v.name+" due to unbound types",p)};Sb([],p,function(I){v.Kd.ie[b-1]=function(){arguments.length!==b-1&&S(E+" called with "+arguments.length+" arguments, expected "+(b-1));x.length=0;r.length=b;for(var L=1;L<b;++L)r[L]=I[L].toWireType(x,arguments[L-1]);L=g.apply(null,r);Ib(x);return I[0].fromWireType(L)};return[]});return[]})},c:function(a,b,c,e,g,m,p,r){var x=Vc(c,e);b=Wb(b);m=Fc(g,m);Sb([],[a],function(v){function E(){Kc("Cannot call "+I+" due to unbound types",
x)}v=v[0];var I=v.name+"."+b;r&&v.Kd.ag.push(b);var L=v.Kd.Ae,y=L[b];void 0===y||void 0===y.Sd&&y.className!==v.name&&y.xe===c-2?(E.xe=c-2,E.className=v.name,L[b]=E):(pc(L,b,I),L[b].Sd[c-2]=E);Sb([],x,function(K){K=Uc(I,K,v,m,p);void 0===L[b].Sd?(K.xe=c-2,L[b]=K):L[b].Sd[c-2]=K;return[]});return[]})},O:function(a,b,c){a=Wb(a);Sb([],[b],function(e){e=e[0];t[a]=e.fromWireType(c);return[]})},Cb:function(a,b){b=Wb(b);Tb(a,{name:b,fromWireType:function(c){var e=Xc[c].value;Yc(c);return e},toWireType:function(c,
e){return wc(e)},argPackAdvance:8,readValueFromPointer:Jb,$d:null})},m:function(a,b,c,e){function g(){}c=Ub(c);b=Wb(b);g.values={};Tb(a,{name:b,constructor:g,fromWireType:function(m){return this.constructor.values[m]},toWireType:function(m,p){return p.value},argPackAdvance:8,readValueFromPointer:Zc(b,c,e),$d:null});qc(b,g)},l:function(a,b,c){var e=$c(a,"enum");b=Wb(b);a=e.constructor;e=Object.create(e.constructor.prototype,{value:{value:c},constructor:{value:Ob(e.name+"_"+b,function(){})}});a.values[c]=
e;a[b]=e},I:function(a,b,c){c=Ub(c);b=Wb(b);Tb(a,{name:b,fromWireType:function(e){return e},toWireType:function(e,g){if("number"!==typeof g&&"boolean"!==typeof g)throw new TypeError('Cannot convert "'+uc(g)+'" to '+this.name);return g},argPackAdvance:8,readValueFromPointer:ad(b,c),$d:null})},r:function(a,b,c,e,g,m){var p=Vc(b,c);a=Wb(a);g=Fc(e,g);qc(a,function(){Kc("Cannot call "+a+" due to unbound types",p)},b-1);Sb([],p,function(r){r=[r[0],null].concat(r.slice(1));Dc(a,Uc(a,r,null,g,m),b-1);return[]})},
u:function(a,b,c,e,g){function m(v){return v}b=Wb(b);-1===g&&(g=4294967295);var p=Ub(c);if(0===e){var r=32-8*c;m=function(v){return v<<r>>>r}}var x=b.includes("unsigned");Tb(a,{name:b,fromWireType:m,toWireType:function(v,E){if("number"!==typeof E&&"boolean"!==typeof E)throw new TypeError('Cannot convert "'+uc(E)+'" to '+this.name);if(E<e||E>g)throw new TypeError('Passing a number "'+uc(E)+'" from JS side to C/C++ side to an argument of type "'+b+'", which is outside the valid range ['+e+", "+g+"]!");
return x?E>>>0:E|0},argPackAdvance:8,readValueFromPointer:bd(b,p,0!==e),$d:null})},s:function(a,b,c){function e(m){m>>=2;var p=mb;return new g(kb,p[m+1],p[m])}var g=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][b];c=Wb(c);Tb(a,{name:c,fromWireType:e,argPackAdvance:8,readValueFromPointer:e},{Sf:!0})},p:function(a,b,c,e,g,m,p,r,x,v,E,I){c=Wb(c);m=Fc(g,m);r=Fc(p,r);v=Fc(x,v);I=Fc(E,I);Sb([a],[b],function(L){L=L[0];return[new Cc(c,L.Kd,!1,!1,!0,L,e,m,r,
v,I)]})},J:function(a,b){b=Wb(b);var c="std::string"===b;Tb(a,{name:b,fromWireType:function(e){var g=mb[e>>2];if(c)for(var m=e+4,p=0;p<=g;++p){var r=e+4+p;if(p==g||0==F[r]){m=Xa(m,r-m);if(void 0===x)var x=m;else x+=String.fromCharCode(0),x+=m;m=r+1}}else{x=Array(g);for(p=0;p<g;++p)x[p]=String.fromCharCode(F[e+4+p]);x=x.join("")}Jc(e);return x},toWireType:function(e,g){g instanceof ArrayBuffer&&(g=new Uint8Array(g));var m="string"===typeof g;m||g instanceof Uint8Array||g instanceof Uint8ClampedArray||
g instanceof Int8Array||S("Cannot pass non-string to std::string");var p=(c&&m?function(){return ka(g)}:function(){return g.length})(),r=Ld(4+p+1);mb[r>>2]=p;if(c&&m)oa(g,F,r+4,p+1);else if(m)for(m=0;m<p;++m){var x=g.charCodeAt(m);255<x&&(Jc(r),S("String has UTF-16 code units that do not fit in 8 bits"));F[r+4+m]=x}else for(m=0;m<p;++m)F[r+4+m]=g[m];null!==e&&e.push(Jc,r);return r},argPackAdvance:8,readValueFromPointer:Jb,$d:function(e){Jc(e)}})},B:function(a,b,c){c=Wb(c);if(2===b){var e=Za;var g=
bb;var m=cb;var p=function(){return $a};var r=1}else 4===b&&(e=db,g=eb,m=fb,p=function(){return mb},r=2);Tb(a,{name:c,fromWireType:function(x){for(var v=mb[x>>2],E=p(),I,L=x+4,y=0;y<=v;++y){var K=x+4+y*b;if(y==v||0==E[K>>r])L=e(L,K-L),void 0===I?I=L:(I+=String.fromCharCode(0),I+=L),L=K+b}Jc(x);return I},toWireType:function(x,v){"string"!==typeof v&&S("Cannot pass non-string to C++ string type "+c);var E=m(v),I=Ld(4+E+b);mb[I>>2]=E>>r;g(v,I+4,E+b);null!==x&&x.push(Jc,I);return I},argPackAdvance:8,
readValueFromPointer:Jb,$d:function(x){Jc(x)}})},N:function(a,b,c,e,g,m){Hb[a]={name:Wb(b),ff:Fc(c,e),ee:Fc(g,m),qf:[]}},w:function(a,b,c,e,g,m,p,r,x,v){Hb[a].qf.push({Hf:Wb(b),Qf:c,Of:Fc(e,g),Pf:m,eg:p,dg:Fc(r,x),fg:v})},Eb:function(a,b){b=Wb(b);Tb(a,{Uf:!0,name:b,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},jb:function(a,b,c,e,g){a=ed[a];b=fd(b);c=dd(c);var m=[];N[e>>2]=wc(m);return a(b,c,m,g)},x:function(a,b,c,e){a=ed[a];b=fd(b);c=dd(c);a(b,c,null,e)},_:Yc,v:function(a,
b){b=hd(a,b);for(var c=b[0],e=c.name+"_$"+b.slice(1).map(function(v){return v.name}).join("_")+"$",g=["retType"],m=[c],p="",r=0;r<a-1;++r)p+=(0!==r?", ":"")+"arg"+r,g.push("argType"+r),m.push(b[1+r]);e="return function "+Nb("methodCaller_"+e)+"(handle, name, destructors, args) {\n";var x=0;for(r=0;r<a-1;++r)e+="    var arg"+r+" = argType"+r+".readValueFromPointer(args"+(x?"+"+x:"")+");\n",x+=b[r+1].argPackAdvance;e+="    var rv = handle[name]("+p+");\n";for(r=0;r<a-1;++r)b[r+1].deleteObject&&(e+=
"    argType"+r+".deleteObject(arg"+r+");\n");c.Uf||(e+="    return retType.toWireType(destructors, rv);\n");g.push(e+"};\n");a=Lc(g).apply(null,m);return gd(a)},kb:function(a){4<a&&(Xc[a].gf+=1)},ja:function(){return wc([])},ua:function(a){return wc(dd(a))},hb:function(){return wc({})},ib:function(a){Ib(Xc[a].value);Yc(a)},A:function(a,b,c){a=fd(a);b=fd(b);c=fd(c);a[b]=c},y:function(a,b){a=$c(a,"_emval_take_value");a=a.readValueFromPointer(b);return wc(a)},d:function(){Na()},pb:function(a,b){if(0===
a)a=Date.now();else if(1===a||4===a)a=jd();else return N[ge()>>2]=28,-1;N[b>>2]=a/1E3|0;N[b+4>>2]=a%1E3*1E6|0;return 0},Yc:function(a){W.activeTexture(a)},Q:function(a,b){W.attachShader(pd[a],td[b])},R:function(a,b,c){W.bindAttribLocation(pd[a],b,Xa(c))},S:function(a,b){35051==a?W.df=b:35052==a&&(W.ye=b);W.bindBuffer(a,od[b])},$b:function(a,b){W.bindFramebuffer(a,qd[b])},ac:function(a,b){W.bindRenderbuffer(a,rd[b])},Nb:function(a,b){W.bindSampler(a,vd[b])},T:function(a,b){W.bindTexture(a,sd[b])},
uc:function(a){W.bindVertexArray(ud[a])},xc:function(a){W.bindVertexArray(ud[a])},U:function(a,b,c,e){W.blendColor(a,b,c,e)},V:function(a){W.blendEquation(a)},W:function(a,b){W.blendFunc(a,b)},Ub:function(a,b,c,e,g,m,p,r,x,v){W.blitFramebuffer(a,b,c,e,g,m,p,r,x,v)},X:function(a,b,c,e){2<=u.version?c?W.bufferData(a,F,e,c,b):W.bufferData(a,b,e):W.bufferData(a,c?F.subarray(c,c+b):b,e)},Y:function(a,b,c,e){2<=u.version?W.bufferSubData(a,b,F,e,c):W.bufferSubData(a,b,F.subarray(e,e+c))},bc:function(a){return W.checkFramebufferStatus(a)},
Z:function(a){W.clear(a)},$:function(a,b,c,e){W.clearColor(a,b,c,e)},aa:function(a){W.clearStencil(a)},eb:function(a,b,c,e){return W.clientWaitSync(wd[a],b,(c>>>0)+4294967296*e)},ba:function(a,b,c,e){W.colorMask(!!a,!!b,!!c,!!e)},ca:function(a){W.compileShader(td[a])},da:function(a,b,c,e,g,m,p,r){2<=u.version?W.ye?W.compressedTexImage2D(a,b,c,e,g,m,p,r):W.compressedTexImage2D(a,b,c,e,g,m,F,r,p):W.compressedTexImage2D(a,b,c,e,g,m,r?F.subarray(r,r+p):null)},ea:function(a,b,c,e,g,m,p,r,x){2<=u.version?
W.ye?W.compressedTexSubImage2D(a,b,c,e,g,m,p,r,x):W.compressedTexSubImage2D(a,b,c,e,g,m,p,F,x,r):W.compressedTexSubImage2D(a,b,c,e,g,m,p,x?F.subarray(x,x+r):null)},fa:function(a,b,c,e,g,m,p,r){W.copyTexSubImage2D(a,b,c,e,g,m,p,r)},ga:function(){var a=Bd(pd),b=W.createProgram();b.name=a;b.Ue=b.Se=b.Te=0;b.hf=1;pd[a]=b;return a},ha:function(a){var b=Bd(td);td[b]=W.createShader(a);return b},ia:function(a){W.cullFace(a)},ka:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2],g=od[e];g&&(W.deleteBuffer(g),
g.name=0,od[e]=null,e==W.df&&(W.df=0),e==W.ye&&(W.ye=0))}},cc:function(a,b){for(var c=0;c<a;++c){var e=N[b+4*c>>2],g=qd[e];g&&(W.deleteFramebuffer(g),g.name=0,qd[e]=null)}},la:function(a){if(a){var b=pd[a];b?(W.deleteProgram(b),b.name=0,pd[a]=null):V(1281)}},dc:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2],g=rd[e];g&&(W.deleteRenderbuffer(g),g.name=0,rd[e]=null)}},Ob:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2],g=vd[e];g&&(W.deleteSampler(g),g.name=0,vd[e]=null)}},ma:function(a){if(a){var b=
td[a];b?(W.deleteShader(b),td[a]=null):V(1281)}},Wb:function(a){if(a){var b=wd[a];b?(W.deleteSync(b),b.name=0,wd[a]=null):V(1281)}},na:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2],g=sd[e];g&&(W.deleteTexture(g),g.name=0,sd[e]=null)}},vc:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2];W.deleteVertexArray(ud[e]);ud[e]=null}},yc:function(a,b){for(var c=0;c<a;c++){var e=N[b+4*c>>2];W.deleteVertexArray(ud[e]);ud[e]=null}},oa:function(a){W.depthMask(!!a)},pa:function(a){W.disable(a)},qa:function(a){W.disableVertexAttribArray(a)},
ra:function(a,b,c){W.drawArrays(a,b,c)},sc:function(a,b,c,e){W.drawArraysInstanced(a,b,c,e)},qc:function(a,b,c,e,g){W.nf.drawArraysInstancedBaseInstanceWEBGL(a,b,c,e,g)},oc:function(a,b){for(var c=Ed[a],e=0;e<a;e++)c[e]=N[b+4*e>>2];W.drawBuffers(c)},sa:function(a,b,c,e){W.drawElements(a,b,c,e)},tc:function(a,b,c,e,g){W.drawElementsInstanced(a,b,c,e,g)},rc:function(a,b,c,e,g,m,p){W.nf.drawElementsInstancedBaseVertexBaseInstanceWEBGL(a,b,c,e,g,m,p)},ic:function(a,b,c,e,g,m){W.drawElements(a,e,g,m)},
ta:function(a){W.enable(a)},va:function(a){W.enableVertexAttribArray(a)},Sb:function(a,b){return(a=W.fenceSync(a,b))?(b=Bd(wd),a.name=b,wd[b]=a,b):0},wa:function(){W.finish()},xa:function(){W.flush()},ec:function(a,b,c,e){W.framebufferRenderbuffer(a,b,c,rd[e])},fc:function(a,b,c,e,g){W.framebufferTexture2D(a,b,c,sd[e],g)},ya:function(a){W.frontFace(a)},za:function(a,b){Id(a,b,"createBuffer",od)},gc:function(a,b){Id(a,b,"createFramebuffer",qd)},hc:function(a,b){Id(a,b,"createRenderbuffer",rd)},Pb:function(a,
b){Id(a,b,"createSampler",vd)},Aa:function(a,b){Id(a,b,"createTexture",sd)},wc:function(a,b){Id(a,b,"createVertexArray",ud)},zc:function(a,b){Id(a,b,"createVertexArray",ud)},Xb:function(a){W.generateMipmap(a)},Ba:function(a,b,c){c?N[c>>2]=W.getBufferParameter(a,b):V(1281)},Ca:function(){var a=W.getError()||Ad;Ad=0;return a},Yb:function(a,b,c,e){a=W.getFramebufferAttachmentParameter(a,b,c);if(a instanceof WebGLRenderbuffer||a instanceof WebGLTexture)a=a.name|0;N[e>>2]=a},cb:function(a,b){Jd(a,b)},
Da:function(a,b,c,e){a=W.getProgramInfoLog(pd[a]);null===a&&(a="(unknown error)");b=0<b&&e?oa(a,F,e,b):0;c&&(N[c>>2]=b)},Ea:function(a,b,c){if(c)if(a>=nd)V(1281);else if(a=pd[a],35716==b)a=W.getProgramInfoLog(a),null===a&&(a="(unknown error)"),N[c>>2]=a.length+1;else if(35719==b){if(!a.Ue)for(b=0;b<W.getProgramParameter(a,35718);++b)a.Ue=Math.max(a.Ue,W.getActiveUniform(a,b).name.length+1);N[c>>2]=a.Ue}else if(35722==b){if(!a.Se)for(b=0;b<W.getProgramParameter(a,35721);++b)a.Se=Math.max(a.Se,W.getActiveAttrib(a,
b).name.length+1);N[c>>2]=a.Se}else if(35381==b){if(!a.Te)for(b=0;b<W.getProgramParameter(a,35382);++b)a.Te=Math.max(a.Te,W.getActiveUniformBlockName(a,b).length+1);N[c>>2]=a.Te}else N[c>>2]=W.getProgramParameter(a,b);else V(1281)},Zb:function(a,b,c){c?N[c>>2]=W.getRenderbufferParameter(a,b):V(1281)},Fa:function(a,b,c,e){a=W.getShaderInfoLog(td[a]);null===a&&(a="(unknown error)");b=0<b&&e?oa(a,F,e,b):0;c&&(N[c>>2]=b)},Kb:function(a,b,c,e){a=W.getShaderPrecisionFormat(a,b);N[c>>2]=a.rangeMin;N[c+4>>
2]=a.rangeMax;N[e>>2]=a.precision},Ga:function(a,b,c){c?35716==b?(a=W.getShaderInfoLog(td[a]),null===a&&(a="(unknown error)"),N[c>>2]=a?a.length+1:0):35720==b?(a=W.getShaderSource(td[a]),N[c>>2]=a?a.length+1:0):N[c>>2]=W.getShaderParameter(td[a],b):V(1281)},D:function(a){var b=xd[a];if(!b){switch(a){case 7939:b=W.getSupportedExtensions()||[];b=b.concat(b.map(function(e){return"GL_"+e}));b=Kd(b.join(" "));break;case 7936:case 7937:case 37445:case 37446:(b=W.getParameter(a))||V(1280);b=b&&Kd(b);break;
case 7938:b=W.getParameter(7938);b=2<=u.version?"OpenGL ES 3.0 ("+b+")":"OpenGL ES 2.0 ("+b+")";b=Kd(b);break;case 35724:b=W.getParameter(35724);var c=b.match(/^WebGL GLSL ES ([0-9]\.[0-9][0-9]?)(?:$| .*)/);null!==c&&(3==c[1].length&&(c[1]+="0"),b="OpenGL ES GLSL ES "+c[1]+" ("+b+")");b=Kd(b);break;default:V(1280)}xd[a]=b}return b},db:function(a,b){if(2>u.version)return V(1282),0;var c=yd[a];if(c)return 0>b||b>=c.length?(V(1281),0):c[b];switch(a){case 7939:return c=W.getSupportedExtensions()||[],
c=c.concat(c.map(function(e){return"GL_"+e})),c=c.map(function(e){return Kd(e)}),c=yd[a]=c,0>b||b>=c.length?(V(1281),0):c[b];default:return V(1280),0}},Ha:function(a,b){function c(y){return"]"==y.slice(-1)&&y.lastIndexOf("[")}b=Xa(b);a=pd[a];var e=a.Ye,g=a.qg,m,p=0,r=b,x=c(b);if(!e)for(a.Ye=e={},a.uf={},m=0;m<W.getProgramParameter(a,35718);++m){var v=W.getActiveUniform(a,m);var E=v.name;v=v.size;var I=c(E);I=0<I?E.slice(0,I):E;var L=a.hf;a.hf+=v;g[I]=[v,L];for(E=0;E<v;++E)e[L]=E,a.uf[L++]=I}0<x&&
(p=parseInt(b.slice(x+1))>>>0,r=b.slice(0,x));return(g=g[r])&&p<g[0]&&(p+=g[1],e[p]=e[p]||W.getUniformLocation(a,b))?p:-1},Lb:function(a,b,c){for(var e=Ed[b],g=0;g<b;g++)e[g]=N[c+4*g>>2];W.invalidateFramebuffer(a,e)},Mb:function(a,b,c,e,g,m,p){for(var r=Ed[b],x=0;x<b;x++)r[x]=N[c+4*x>>2];W.invalidateSubFramebuffer(a,r,e,g,m,p)},Tb:function(a){return W.isSync(wd[a])},Ia:function(a){return(a=sd[a])?W.isTexture(a):0},Ja:function(a){W.lineWidth(a)},Ka:function(a){a=pd[a];W.linkProgram(a);a.Ye=0;a.qg=
{}},mc:function(a,b,c,e,g,m){W.sf.multiDrawArraysInstancedBaseInstanceWEBGL(a,N,b>>2,N,c>>2,N,e>>2,mb,g>>2,m)},nc:function(a,b,c,e,g,m,p,r){W.sf.multiDrawElementsInstancedBaseVertexBaseInstanceWEBGL(a,N,b>>2,c,N,e>>2,N,g>>2,N,m>>2,mb,p>>2,r)},La:function(a,b){3317==a&&(zd=b);W.pixelStorei(a,b)},pc:function(a){W.readBuffer(a)},Ma:function(a,b,c,e,g,m,p){if(2<=u.version)if(W.df)W.readPixels(a,b,c,e,g,m,p);else{var r=Md(m);W.readPixels(a,b,c,e,g,m,r,p>>31-Math.clz32(r.BYTES_PER_ELEMENT))}else(p=Nd(m,
g,c,e,p))?W.readPixels(a,b,c,e,g,m,p):V(1280)},_b:function(a,b,c,e){W.renderbufferStorage(a,b,c,e)},Vb:function(a,b,c,e,g){W.renderbufferStorageMultisample(a,b,c,e,g)},Qb:function(a,b,c){W.samplerParameteri(vd[a],b,c)},Rb:function(a,b,c){W.samplerParameteri(vd[a],b,N[c>>2])},Na:function(a,b,c,e){W.scissor(a,b,c,e)},Oa:function(a,b,c,e){for(var g="",m=0;m<b;++m){var p=e?N[e+4*m>>2]:-1;g+=Xa(N[c+4*m>>2],0>p?void 0:p)}W.shaderSource(td[a],g)},Pa:function(a,b,c){W.stencilFunc(a,b,c)},Qa:function(a,b,
c,e){W.stencilFuncSeparate(a,b,c,e)},Ra:function(a){W.stencilMask(a)},Sa:function(a,b){W.stencilMaskSeparate(a,b)},Ta:function(a,b,c){W.stencilOp(a,b,c)},Ua:function(a,b,c,e){W.stencilOpSeparate(a,b,c,e)},Va:function(a,b,c,e,g,m,p,r,x){if(2<=u.version)if(W.ye)W.texImage2D(a,b,c,e,g,m,p,r,x);else if(x){var v=Md(r);W.texImage2D(a,b,c,e,g,m,p,r,v,x>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else W.texImage2D(a,b,c,e,g,m,p,r,null);else W.texImage2D(a,b,c,e,g,m,p,r,x?Nd(r,p,e,g,x):null)},Wa:function(a,b,c){W.texParameterf(a,
b,c)},Xa:function(a,b,c){W.texParameterf(a,b,Q[c>>2])},Ya:function(a,b,c){W.texParameteri(a,b,c)},_a:function(a,b,c){W.texParameteri(a,b,N[c>>2])},jc:function(a,b,c,e,g){W.texStorage2D(a,b,c,e,g)},$a:function(a,b,c,e,g,m,p,r,x){if(2<=u.version)if(W.ye)W.texSubImage2D(a,b,c,e,g,m,p,r,x);else if(x){var v=Md(r);W.texSubImage2D(a,b,c,e,g,m,p,r,v,x>>31-Math.clz32(v.BYTES_PER_ELEMENT))}else W.texSubImage2D(a,b,c,e,g,m,p,r,null);else v=null,x&&(v=Nd(r,p,g,m,x)),W.texSubImage2D(a,b,c,e,g,m,p,r,v)},ab:function(a,
b){W.uniform1f(Y(a),b)},bb:function(a,b,c){if(2<=u.version)W.uniform1fv(Y(a),Q,c>>2,b);else{if(288>=b)for(var e=Od[b-1],g=0;g<b;++g)e[g]=Q[c+4*g>>2];else e=Q.subarray(c>>2,c+4*b>>2);W.uniform1fv(Y(a),e)}},Uc:function(a,b){W.uniform1i(Y(a),b)},Vc:function(a,b,c){if(2<=u.version)W.uniform1iv(Y(a),N,c>>2,b);else{if(288>=b)for(var e=Pd[b-1],g=0;g<b;++g)e[g]=N[c+4*g>>2];else e=N.subarray(c>>2,c+4*b>>2);W.uniform1iv(Y(a),e)}},Wc:function(a,b,c){W.uniform2f(Y(a),b,c)},Xc:function(a,b,c){if(2<=u.version)W.uniform2fv(Y(a),
Q,c>>2,2*b);else{if(144>=b)for(var e=Od[2*b-1],g=0;g<2*b;g+=2)e[g]=Q[c+4*g>>2],e[g+1]=Q[c+(4*g+4)>>2];else e=Q.subarray(c>>2,c+8*b>>2);W.uniform2fv(Y(a),e)}},Tc:function(a,b,c){W.uniform2i(Y(a),b,c)},Sc:function(a,b,c){if(2<=u.version)W.uniform2iv(Y(a),N,c>>2,2*b);else{if(144>=b)for(var e=Pd[2*b-1],g=0;g<2*b;g+=2)e[g]=N[c+4*g>>2],e[g+1]=N[c+(4*g+4)>>2];else e=N.subarray(c>>2,c+8*b>>2);W.uniform2iv(Y(a),e)}},Rc:function(a,b,c,e){W.uniform3f(Y(a),b,c,e)},Qc:function(a,b,c){if(2<=u.version)W.uniform3fv(Y(a),
Q,c>>2,3*b);else{if(96>=b)for(var e=Od[3*b-1],g=0;g<3*b;g+=3)e[g]=Q[c+4*g>>2],e[g+1]=Q[c+(4*g+4)>>2],e[g+2]=Q[c+(4*g+8)>>2];else e=Q.subarray(c>>2,c+12*b>>2);W.uniform3fv(Y(a),e)}},Pc:function(a,b,c,e){W.uniform3i(Y(a),b,c,e)},Oc:function(a,b,c){if(2<=u.version)W.uniform3iv(Y(a),N,c>>2,3*b);else{if(96>=b)for(var e=Pd[3*b-1],g=0;g<3*b;g+=3)e[g]=N[c+4*g>>2],e[g+1]=N[c+(4*g+4)>>2],e[g+2]=N[c+(4*g+8)>>2];else e=N.subarray(c>>2,c+12*b>>2);W.uniform3iv(Y(a),e)}},Nc:function(a,b,c,e,g){W.uniform4f(Y(a),
b,c,e,g)},Mc:function(a,b,c){if(2<=u.version)W.uniform4fv(Y(a),Q,c>>2,4*b);else{if(72>=b){var e=Od[4*b-1],g=Q;c>>=2;for(var m=0;m<4*b;m+=4){var p=c+m;e[m]=g[p];e[m+1]=g[p+1];e[m+2]=g[p+2];e[m+3]=g[p+3]}}else e=Q.subarray(c>>2,c+16*b>>2);W.uniform4fv(Y(a),e)}},Ac:function(a,b,c,e,g){W.uniform4i(Y(a),b,c,e,g)},Bc:function(a,b,c){if(2<=u.version)W.uniform4iv(Y(a),N,c>>2,4*b);else{if(72>=b)for(var e=Pd[4*b-1],g=0;g<4*b;g+=4)e[g]=N[c+4*g>>2],e[g+1]=N[c+(4*g+4)>>2],e[g+2]=N[c+(4*g+8)>>2],e[g+3]=N[c+(4*
g+12)>>2];else e=N.subarray(c>>2,c+16*b>>2);W.uniform4iv(Y(a),e)}},Cc:function(a,b,c,e){if(2<=u.version)W.uniformMatrix2fv(Y(a),!!c,Q,e>>2,4*b);else{if(72>=b)for(var g=Od[4*b-1],m=0;m<4*b;m+=4)g[m]=Q[e+4*m>>2],g[m+1]=Q[e+(4*m+4)>>2],g[m+2]=Q[e+(4*m+8)>>2],g[m+3]=Q[e+(4*m+12)>>2];else g=Q.subarray(e>>2,e+16*b>>2);W.uniformMatrix2fv(Y(a),!!c,g)}},Dc:function(a,b,c,e){if(2<=u.version)W.uniformMatrix3fv(Y(a),!!c,Q,e>>2,9*b);else{if(32>=b)for(var g=Od[9*b-1],m=0;m<9*b;m+=9)g[m]=Q[e+4*m>>2],g[m+1]=Q[e+
(4*m+4)>>2],g[m+2]=Q[e+(4*m+8)>>2],g[m+3]=Q[e+(4*m+12)>>2],g[m+4]=Q[e+(4*m+16)>>2],g[m+5]=Q[e+(4*m+20)>>2],g[m+6]=Q[e+(4*m+24)>>2],g[m+7]=Q[e+(4*m+28)>>2],g[m+8]=Q[e+(4*m+32)>>2];else g=Q.subarray(e>>2,e+36*b>>2);W.uniformMatrix3fv(Y(a),!!c,g)}},Ec:function(a,b,c,e){if(2<=u.version)W.uniformMatrix4fv(Y(a),!!c,Q,e>>2,16*b);else{if(18>=b){var g=Od[16*b-1],m=Q;e>>=2;for(var p=0;p<16*b;p+=16){var r=e+p;g[p]=m[r];g[p+1]=m[r+1];g[p+2]=m[r+2];g[p+3]=m[r+3];g[p+4]=m[r+4];g[p+5]=m[r+5];g[p+6]=m[r+6];g[p+7]=
m[r+7];g[p+8]=m[r+8];g[p+9]=m[r+9];g[p+10]=m[r+10];g[p+11]=m[r+11];g[p+12]=m[r+12];g[p+13]=m[r+13];g[p+14]=m[r+14];g[p+15]=m[r+15]}}else g=Q.subarray(e>>2,e+64*b>>2);W.uniformMatrix4fv(Y(a),!!c,g)}},Fc:function(a){a=pd[a];W.useProgram(a);W.Df=a},Gc:function(a,b){W.vertexAttrib1f(a,b)},Hc:function(a,b){W.vertexAttrib2f(a,Q[b>>2],Q[b+4>>2])},Ic:function(a,b){W.vertexAttrib3f(a,Q[b>>2],Q[b+4>>2],Q[b+8>>2])},Jc:function(a,b){W.vertexAttrib4f(a,Q[b>>2],Q[b+4>>2],Q[b+8>>2],Q[b+12>>2])},kc:function(a,b){W.vertexAttribDivisor(a,
b)},lc:function(a,b,c,e,g){W.vertexAttribIPointer(a,b,c,e,g)},Kc:function(a,b,c,e,g,m){W.vertexAttribPointer(a,b,c,!!e,g,m)},Lc:function(a,b,c,e){W.viewport(a,b,c,e)},fb:function(a,b,c,e){W.waitSync(wd[a],b,(c>>>0)+4294967296*e)},e:function(a,b){he(a,b||1);throw"longjmp";},ob:function(a){var b=F.length;a>>>=0;if(2147483648<a)return!1;for(var c=1;4>=c;c*=2){var e=b*(1+.2/c);e=Math.min(e,a+100663296);e=Math.max(a,e);0<e%65536&&(e+=65536-e%65536);a:{try{Ta.grow(Math.min(2147483648,e)-kb.byteLength+65535>>>
16);ob();var g=1;break a}catch(m){}g=void 0}if(g)return!0}return!1},Zc:function(){return u?u.Rf:0},P:function(a){return ia(a)?0:-5},sb:function(a,b){var c=0;Rd().forEach(function(e,g){var m=b+c;g=N[a+4*g>>2]=m;for(m=0;m<e.length;++m)lb[g++>>0]=e.charCodeAt(m);lb[g>>0]=0;c+=e.length+1});return 0},tb:function(a,b){var c=Rd();N[a>>2]=c.length;var e=0;c.forEach(function(g){e+=g.length+1});N[b>>2]=e;return 0},Fb:function(a){if(!noExitRuntime){if(t.onExit)t.onExit(a);Ua=!0}ta(a,new Ma(a))},H:function(){return 0},
rb:function(a,b){a=1==a||2==a?2:Na();lb[b>>0]=a;return 0},mb:function(a,b,c,e,g,m){a=Gb.Nf(a);b=Gb.Ef(a,b,c,e);N[m>>2]=b;return 0},yb:function(a,b,c,e){a=Gb.Nf(a);b=Gb.Ef(a,b,c);N[e>>2]=b;return 0},lb:function(){},G:function(a,b,c,e){for(var g=0,m=0;m<c;m++){for(var p=N[b+8*m>>2],r=N[b+(8*m+4)>>2],x=0;x<r;x++){var v=F[p+x],E=Fb[a];0===v||10===v?((1===a?Oa:Pa)(Wa(E,0)),E.length=0):E.push(v)}g+=r}N[e>>2]=g;return 0},a:function(){return Qa},_c:function(a,b){W.bindFramebuffer(a,qd[b])},Za:function(a){W.clear(a)},
Ab:function(a,b,c,e){W.clearColor(a,b,c,e)},gb:function(a){W.clearStencil(a)},C:function(a,b){Jd(a,b)},i:ie,n:je,g:ke,z:le,Jb:me,M:ne,L:oe,K:pe,j:qe,q:re,h:se,o:te,Ib:ue,Gb:ve,Hb:we,b:function(a){Qa=a},qb:function(a,b,c,e){return Yd(a,b,c,e)}};
(function(){function a(g){t.asm=g.exports;Ta=t.asm.$c;ob();pb=t.asm.cd;rb.unshift(t.asm.ad);ub--;t.monitorRunDependencies&&t.monitorRunDependencies(ub);0==ub&&(null!==vb&&(clearInterval(vb),vb=null),wb&&(g=wb,wb=null,g()))}function b(g){a(g.instance)}function c(g){return Cb().then(function(m){return WebAssembly.instantiate(m,e)}).then(g,function(m){Pa("failed to asynchronously prepare wasm: "+m);Na(m)})}var e={a:xe};ub++;t.monitorRunDependencies&&t.monitorRunDependencies(ub);if(t.instantiateWasm)try{return t.instantiateWasm(e,
a)}catch(g){return Pa("Module.instantiateWasm callback failed with error: "+g),!1}(function(){return Sa||"function"!==typeof WebAssembly.instantiateStreaming||xb()||yb.startsWith("file://")||"function"!==typeof fetch?c(b):fetch(yb,{credentials:"same-origin"}).then(function(g){return WebAssembly.instantiateStreaming(g,e).then(b,function(m){Pa("wasm streaming compile failed: "+m);Pa("falling back to ArrayBuffer instantiation");return c(b)})})})().catch(ca);return{}})();
t.___wasm_call_ctors=function(){return(t.___wasm_call_ctors=t.asm.ad).apply(null,arguments)};var fe=t._memset=function(){return(fe=t._memset=t.asm.bd).apply(null,arguments)},ge=t.___errno_location=function(){return(ge=t.___errno_location=t.asm.dd).apply(null,arguments)},Jc=t._free=function(){return(Jc=t._free=t.asm.ed).apply(null,arguments)},Ld=t._malloc=function(){return(Ld=t._malloc=t.asm.fd).apply(null,arguments)},Ic=t.___getTypeName=function(){return(Ic=t.___getTypeName=t.asm.gd).apply(null,arguments)};
t.___embind_register_native_and_builtin_types=function(){return(t.___embind_register_native_and_builtin_types=t.asm.hd).apply(null,arguments)};var ye=t.stackSave=function(){return(ye=t.stackSave=t.asm.id).apply(null,arguments)},ze=t.stackRestore=function(){return(ze=t.stackRestore=t.asm.jd).apply(null,arguments)},he=t._setThrew=function(){return(he=t._setThrew=t.asm.kd).apply(null,arguments)},ee=t._memalign=function(){return(ee=t._memalign=t.asm.ld).apply(null,arguments)};
t.dynCall_viji=function(){return(t.dynCall_viji=t.asm.md).apply(null,arguments)};t.dynCall_vijiii=function(){return(t.dynCall_vijiii=t.asm.nd).apply(null,arguments)};t.dynCall_viiiiij=function(){return(t.dynCall_viiiiij=t.asm.od).apply(null,arguments)};t.dynCall_ji=function(){return(t.dynCall_ji=t.asm.pd).apply(null,arguments)};t.dynCall_jii=function(){return(t.dynCall_jii=t.asm.qd).apply(null,arguments)};t.dynCall_viijii=function(){return(t.dynCall_viijii=t.asm.rd).apply(null,arguments)};
t.dynCall_iiij=function(){return(t.dynCall_iiij=t.asm.sd).apply(null,arguments)};t.dynCall_iiiij=function(){return(t.dynCall_iiiij=t.asm.td).apply(null,arguments)};t.dynCall_viij=function(){return(t.dynCall_viij=t.asm.ud).apply(null,arguments)};t.dynCall_viiij=function(){return(t.dynCall_viiij=t.asm.vd).apply(null,arguments)};t.dynCall_iij=function(){return(t.dynCall_iij=t.asm.wd).apply(null,arguments)};t.dynCall_vij=function(){return(t.dynCall_vij=t.asm.xd).apply(null,arguments)};
t.dynCall_jiiii=function(){return(t.dynCall_jiiii=t.asm.yd).apply(null,arguments)};t.dynCall_jiiiiii=function(){return(t.dynCall_jiiiiii=t.asm.zd).apply(null,arguments)};t.dynCall_jiiiiji=function(){return(t.dynCall_jiiiiji=t.asm.Ad).apply(null,arguments)};t.dynCall_iijj=function(){return(t.dynCall_iijj=t.asm.Bd).apply(null,arguments)};t.dynCall_jiji=function(){return(t.dynCall_jiji=t.asm.Cd).apply(null,arguments)};t.dynCall_iiiiij=function(){return(t.dynCall_iiiiij=t.asm.Dd).apply(null,arguments)};
t.dynCall_iiiiijj=function(){return(t.dynCall_iiiiijj=t.asm.Ed).apply(null,arguments)};t.dynCall_iiiiiijj=function(){return(t.dynCall_iiiiiijj=t.asm.Fd).apply(null,arguments)};function ie(a,b){var c=ye();try{return pb.get(a)(b)}catch(e){ze(c);if(e!==e+0&&"longjmp"!==e)throw e;he(1,0)}}function je(a,b,c){var e=ye();try{return pb.get(a)(b,c)}catch(g){ze(e);if(g!==g+0&&"longjmp"!==g)throw g;he(1,0)}}
function se(a,b,c,e){var g=ye();try{pb.get(a)(b,c,e)}catch(m){ze(g);if(m!==m+0&&"longjmp"!==m)throw m;he(1,0)}}function ke(a,b,c,e){var g=ye();try{return pb.get(a)(b,c,e)}catch(m){ze(g);if(m!==m+0&&"longjmp"!==m)throw m;he(1,0)}}function qe(a,b){var c=ye();try{pb.get(a)(b)}catch(e){ze(c);if(e!==e+0&&"longjmp"!==e)throw e;he(1,0)}}function re(a,b,c){var e=ye();try{pb.get(a)(b,c)}catch(g){ze(e);if(g!==g+0&&"longjmp"!==g)throw g;he(1,0)}}
function me(a,b,c,e,g,m){var p=ye();try{return pb.get(a)(b,c,e,g,m)}catch(r){ze(p);if(r!==r+0&&"longjmp"!==r)throw r;he(1,0)}}function te(a,b,c,e,g){var m=ye();try{pb.get(a)(b,c,e,g)}catch(p){ze(m);if(p!==p+0&&"longjmp"!==p)throw p;he(1,0)}}function ne(a,b,c,e,g,m,p){var r=ye();try{return pb.get(a)(b,c,e,g,m,p)}catch(x){ze(r);if(x!==x+0&&"longjmp"!==x)throw x;he(1,0)}}function le(a,b,c,e,g){var m=ye();try{return pb.get(a)(b,c,e,g)}catch(p){ze(m);if(p!==p+0&&"longjmp"!==p)throw p;he(1,0)}}
function ue(a,b,c,e,g,m){var p=ye();try{pb.get(a)(b,c,e,g,m)}catch(r){ze(p);if(r!==r+0&&"longjmp"!==r)throw r;he(1,0)}}function we(a,b,c,e,g,m,p,r,x,v){var E=ye();try{pb.get(a)(b,c,e,g,m,p,r,x,v)}catch(I){ze(E);if(I!==I+0&&"longjmp"!==I)throw I;he(1,0)}}function ve(a,b,c,e,g,m,p){var r=ye();try{pb.get(a)(b,c,e,g,m,p)}catch(x){ze(r);if(x!==x+0&&"longjmp"!==x)throw x;he(1,0)}}
function oe(a,b,c,e,g,m,p,r,x,v){var E=ye();try{return pb.get(a)(b,c,e,g,m,p,r,x,v)}catch(I){ze(E);if(I!==I+0&&"longjmp"!==I)throw I;he(1,0)}}function pe(a){var b=ye();try{pb.get(a)()}catch(c){ze(b);if(c!==c+0&&"longjmp"!==c)throw c;he(1,0)}}var Ae;function Ma(a){this.name="ExitStatus";this.message="Program terminated with exit("+a+")";this.status=a}wb=function Be(){Ae||Ce();Ae||(wb=Be)};
function Ce(){function a(){if(!Ae&&(Ae=!0,t.calledRun=!0,!Ua)){Db(rb);aa(t);if(t.onRuntimeInitialized)t.onRuntimeInitialized();if(t.postRun)for("function"==typeof t.postRun&&(t.postRun=[t.postRun]);t.postRun.length;){var b=t.postRun.shift();sb.unshift(b)}Db(sb)}}if(!(0<ub)){if(t.preRun)for("function"==typeof t.preRun&&(t.preRun=[t.preRun]);t.preRun.length;)tb();Db(qb);0<ub||(t.setStatus?(t.setStatus("Running..."),setTimeout(function(){setTimeout(function(){t.setStatus("")},1);a()},1)):a())}}
t.run=Ce;if(t.preInit)for("function"==typeof t.preInit&&(t.preInit=[t.preInit]);0<t.preInit.length;)t.preInit.pop()();Ce();


  return CanvasKitInit.ready
}
);
})();
if (typeof exports === 'object' && typeof module === 'object')
  module.exports = CanvasKitInit;
else if (typeof define === 'function' && define['amd'])
  define([], function() { return CanvasKitInit; });
else if (typeof exports === 'object')
  exports["CanvasKitInit"] = CanvasKitInit;
